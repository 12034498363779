<template>
	<div class="mineAddress">
    	<div class="title">
			收货地址管理
			<div class="btnBox">
				<!-- <el-button style="background: #219560; " type="primary" @click="toAddSite" >
					添加地址
				</el-button> -->
				<p class="remove" @click="toAddSite">添加地址</p>
			</div>
		</div>
		<div class="addressBox" v-for="item in addressList" :key="item.id" >
			<div class="top">
				<div class="name">{{ item.receiptPersonName }}</div>
				<div class="phone">{{ item.receiptPhone }}</div>
				<div class="tag" v-if="item.isFault==1">
					<div :class="{red:item.isFault=='1'}">默认</div>
				</div>
			</div>
			<div class="bor"></div>
			<div class="bottom">
				<div class="addres">
					<div class="city">省市区：{{item.detailArea}}</div>
					<div class="cityDetail">详细地址：{{item.detailAddress}}</div>
				</div>

				<div class="edit">
					<div class="editBtn" @click="editAddress(item)">
						<i class="el-icon-edit"></i>
						编辑
					</div>
					<div class="delBtn" @click="delAddress(item)">
						<i class="el-icon-delete"></i>
						删除
					</div>
				</div>
			</div>
		</div>
		<pagination style="margin: 0 auto" v-show="Total > 0" :total="Total" 
			:page.sync="form.pageNum" :limit.sync="form.pageSize" @pagination="getAddressListApi" />
		<div >
			<!-- <el-button style="background: #219560; margin-left: 360px" type="primary" @click="toAddSite" >
				添加地址
			</el-button> -->
			<!-- <button @click="toAddSite">
				添加地址
				<u-icon name="plus" color="#ffffff" class="icon" :size="30"></u-icon>
			</button> -->
		</div>
	</div>
</template>
  
<script>
import { getAddressList, getAddressDetail, getAddressAdd, getAddressUpdata, AddressRemove } from "@/request/mine.js";
// import { throttle } from "@/utils/throttle.js"; //引入节流函数
import Pagination from "@/components/Pagination/Pagination";
//   import OSS from "ali-oss";
//   import { getUserData, subUserData } from "@/request/mine";
export default {
	components: { Pagination },
	data() {
		return {
			addressList: [],
			type: 0,
      		Total: 0,
			form: {
				pageNum: 1,
				pageSize: 5,
			},
		};
	},
	created() {
		this.getAddressListApi()
	},
	methods: {
		getAddressListApi() {
			const scope = this
			getAddressList({ params: this.form}).then((res) => {
				if (res.code == 200) {
					scope.addressList = res.rows
					scope.Total = res.total
				}
				// this.addressList = res.rows
			})
		},
		toAddSite() {
			// const fn = throttle(function() {
				this.$router.push({path: 'newAddress'})
			// })
			// fn()
		},
		editAddress(item) {
			this.$router.push({
				path: 'newAddress',
				query: {
					id: item.id,
					edit: true
				}
			})
		},
		delAddress(item) {
			const scope = this
			// uni.showModal({
			// 	title: '删除提示',
			// 	content: '确认要删除该地址吗？',
			// 	success: function(res) {
			// 		if (res.confirm) {
			// 			AddressRemove({id: scope.id}).then((res)=>{
			// 				if(res.code == 0){
			// 					uni.navigateBack({
			// 						url:"/pagesA/mine/mineAddress",
			// 					})
			// 				}
			// 			})
						
			// 		}
			// 	}
			// })

			this.$confirm("确认要删除该地址吗？", "删除提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			}).then(() => {
				AddressRemove({id: item.id}).then((res) => {
					if (res.code == 0) {
						scope.form.pageNum = 1
						scope.getAddressListApi()
					}
				})
			}).catch(() => {});

		}
	  
	},
  };
  </script>
  
<style lang="scss" scoped>
.mineAddress {
	width: 100%;
	height: 100vh;
	// background-color: #f7f7f7;
	padding: 30px 0px;

	.title {
		width: 86%;
		padding: 0 0 20px 30px;
		margin-bottom: 20px;
		font-size: 16px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #333333;
		border-bottom: 1px solid #ebebeb;
		display: flex;
		justify-content: space-between;

		.btnBox {
			// margin-right: 30px;
			width: 140px;
			display: flex;
			.remove {
				cursor: pointer;
				margin-top: 5px;
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				// color: #999999;
				color: dodgerblue;
			}
		}
	}

	.addressBox {
		background-color: #fff;
		padding: 15px 15px;
		margin-bottom: 10px;
		margin-left: 100px;
		width: 70%;

		.top {
			display: flex;
			font-weight: bold;
			align-items: center;
			font-size: 14px;
			margin-bottom: 10px;
			margin-left: 40px;

			.phone {
				margin-left: 120px;
			}

			.tag {
				display: flex;
				margin-left: 30px;

				div {
					align-self: flex-end;
					display: block;
					width: 40px;
					height: 25px;
					line-height: 25px;
					color: #ffffff;
					font-size: 12px;
					border-radius: 6px;
					text-align: center;
					background-color: rgb(49, 145, 253);
				}

				.red {
					background-color: rgb(251, 95, 95)
				}
			}
		}

		.bor {
			width: 100%;
			height: 1px;
			background-color: #ebebeb;
		}

		.bottom {
			display: flex;
			margin-top: 10px;
			margin-left: 40px;
			font-size: 14px;
			justify-content: space-between;
			align-items: center;
			color: #999999;
			.addres{
				display: flex;
				flex-direction: column;
				width: 500px;
			}
			.edit{
				// flex-direction: row;
				// width: 200px;
				display: flex;
				margin-left: 10px;
				
				.editBtn{
					color:dodgerblue;
					cursor: pointer;
				}
				.delBtn{
					color: crimson;
					margin-left: 20px;
					cursor: pointer;
				}
			}

			.remove{
				margin-left: 10px;
				color:dodgerblue;
				cursor: pointer;
			}
		}


	}

	.addSite {
		display: flex;
		justify-content: space-around;
		width: 92%;
		height: 88px;
		background-color: #F4631F;
		margin: 0 auto;
		line-height: 100px;
		position: absolute;
		bottom: 20px;
		border-radius: 60px;
		font-size: 14px;

		.add {
			display: flex;
			align-items: center;
			color: #ffffff;

			.icon {
				margin-right: 10px;
			}
		}
	}
}
</style>