import request from '@/request/request'

// const getAddressListUrl = "/system/info/address/list"                                       // 地址管理列表
// const getAddressDetailUrl = "/system/info/address/getAddressInfo"                           // 地址管理详情
// const getAddressAddUrl = "/system/info/address/add"                                         // 地址管理保存
// const getAddressUpdataUrl = "/system/info/address/update"                                   // 地址管理编辑保存
// const AddressRemoveUrl = "/system/info/address/remove"                                      // 地址管理删除

// const findBizShopGoodsOrderUrl = "/bizShopGoodsOrderManage/findBizShopGoodsOrder"           // 查询商品订单列表
// const findBizShopGoodsOrderDetailUrl = "/bizShopGoodsOrderManage/findBizShopGoodsOrderDetail"     // 查询商品订单列表
// const paymentAppPayUrl = "/mp/third/wxPay/jsapi/v3/paymentAppPay"                           // 单订单支付
// const confirmReceiptUrl = "/bizShopGoodsOrderManage/confirmReceipt"                         // 确认收货
// const searchOrderInvoiceUrl = "/bizShopGoodsOrderManage/searchOrderInvoice"                 // 查看发票
// const removeShopOrderUrl = "/bizShopGoodsOrderManage/cancelOrder"                           // 取消订单

function baseCall(url, method, data) {
    return request({
        url: url,
        headers: {
            Authorization: sessionStorage.getItem("token")
        },
        method: method,
        data: data
    })
}

//开票新增接口
export function baseVisitorInvoiceAdd(data) {
	return baseCall('api/orderInvoice/add', "post", data)
}

//用户开票列表
export function baseVisitorInvoiceList(data) {
	return baseCall('api/orderInvoice/list', "get", data)
}

//开票更新接口
export function baseVisitorInvoiceUpdate(data) {
	return baseCall('api/orderInvoice/update', "post", data)
}

//创建商城订单接口
export function createShopOrder(data) {
	return baseCall('mp/bizShopCart/createShopOrder', "post", data)
}

// 合单支付
export function paymentAppPays(data) {
	return baseCall('mp/third/wxPay/jsapi/combined/v3/paymentAppPay', "post", data)
}

//跳转支付界面
export function getOrderUnionPayment(data) {
 	return baseCall('api/payment/getOrderUnionPayment', "post", data)
}

//生成支付二维码
export function getOrderPayment(data) {
	return baseCall('api/payment/getOrderPayment', "post", data)
}

//查询支付结果
export function payConfirm(data) {
	return baseCall('api/payment/payConfirm', "post", data)
}

//商城下单支付创建订单
export function directPurchase(data) {
 	return baseCall('api/payment/directPurchase', "post", data)
}

//商品订单列表
export function findBizShopGoodsOrder(data) {
 	return baseCall('mp/bizShopGoodsOrder/findBizShopGoodsOrder', "post", data)
}

//商品订单详情
export function findBizShopGoodsOrderDetail(data) {
 	return baseCall('mp/bizShopGoodsOrder/findBizShopGoodsOrderDetail', "post", data)
}

// //确认收货
// export function confirmReceipt(data) {
//     return baseCall('mp/bizShopGoodsOrder/confirmReceipt', "post", data)
// }

//查看发票 
export function searchOrderInvoice(data) {
 	return baseCall('mp/bizShopGoodsOrder/searchOrderInvoice', "post", data)
}
 
// //取消订单
// export function removeShopOrder(data) {
//  	return baseCall('mp/bizShopGoodsOrder/cancelOrder', "post", data)
// }

//退款
export function refund(data) {
	return baseCall('api/payment/refund', "post", data)
}

export function submitRefund(data) {
	return baseCall('api/payment/submitRefund', "post", data)
}

// 微信支付申请
export function getWechatPayment(data) {
	return baseCall('api/payment/wechat/getOrderPayment', "post", data)
}