<template>
	<div class="MineShoppingOrder">
		<!-- <el-toast ref="uToast" /> -->
		<el-tabs @change="SwiperTabs" bg-color="#ffffff" :bold="false" :list="tabsList" active-color="#009A74" @tab-click="refreshTab"
			font-size="16px" inactive-color="#3D3D3D" :is-scroll="true" :current="Current" :show-bar="false">
            
            <!-- <div v-for="item in tabsList" :key="item.orderStatus"> -->
                <el-tab-pane v-for="item in tabsList" :key="item.orderStatus" :label="item.name" :lazy="true">
                    <div v-if="currentTabName == item.name">
                        <baseOrderList :ref="item.name" :orderTypeInfo="item" ></baseOrderList>
                    </div>
                </el-tab-pane>
            <!-- </div> -->

            
        </el-tabs>
		<!-- <el-popup mode="bottom" closeable="true" border-radius="15" width="630" height="360" v-model="showMobeil">
			<div class="PopupMobeil">
				<div class="Top">
					<div class="topUs">
						<div class="topUsTitle">咨询客服</div>
						<div class="topUsTitleCon">工作时间：工作日 9:00-18:00</div>
					</div>
				</div>
				<div class="consult">
					<el-button :custom-style="customStyle1" :hair-line="false" open-type="contact" hover-class="none"
						bindcontact="handleContact" send-message-path session-from="sessionFrom">
						<div class="leftIcon">
							<img src="@assets/icon/zixunerji.png" mode=""/>
						</div>
						<div class="leftText">
							<div class="textTop">在线客服</div>
							<div class="textBottom">及时解决您的问题</div>
						</div>
					</el-button>
					<div class="consultLeft"></div>
					<div class="consultRight" @click="callPhone">
						<div class="rightIcon">
							<img src="@assets/icon/zixunPhone.png" mode=""/>
						</div>
						<div class="rightText">
							<div class="textTop">电话客服</div>
							<div class="textBottom">029-85233571</div>
						</div>
					</div>
				</div>
			</div>
		</el-popup> -->
		<!-- <el-toast ref="uToast" /> -->
		<!-- <el-modal v-model="showReceipt" content="请先确认订单是否到货？" :show-cancel-button="true" @confirm="TrueReceipt">
		</el-modal> -->
	</div>
</template>

<script>
import {
    findBizShopGoodsOrder,
    paymentAppPay,
    confirmReceipt,
    searchOrderInvoice,
    removeShopOrder
} from '@/request/mine.js';
import baseOrderList from '@/components/order/baseOrderList.vue'
// import { throttle } from "@/utils/throttle.js"; //引入节流函数
export default {
    components: { baseOrderList },
    data() {
        return {
            customStyle1: {
                width: '324px',
                height: '160px',
                backgroundColor: '#F7F7F7',
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                padding: '0 20px',
                border: 'none'
            },
            showMobeil: false,
            Current: 0,
            currentTabName: '全部订单',
            tabsList: [{
                orderStatus: 0,
                name: '全部订单'
            }, {
                orderStatus: 1,
                name: '待付款'
            }, {
                orderStatus: 5,
                name: '待发货'
            }, {
                orderStatus: 6,
                name: '待收货'
            }, {
                orderStatus: 10,
                name: '已完成'
            }, {
                orderStatus: 3,
                name: '已取消'
            }, {
                orderStatus: 15,
                name: '待退款'
            }, {
                orderStatus: 20,
                name: '已退款'
            }],
            //全部订单
            allOrderIs: false,
            allOrderList: [],
            //待付款
            OneOrderIs: false,
            OneOrderList: [],
            //代发货
            TwoOrderIs: false,
            TwoOrderList: [],
            //待收货
            ThereOrderIs: false,
            ThereOrderList: [],
            showReceipt: false,
            //已完成
            FourOrderIs: false,
            FourOrderList: [],
            //已取消
            FiveOrderIs: false,
            FiveOrderList: [],
            OrderId: ''
        };
    },
    created() {
        //全部订单
        // this.allOrderIs = false;
        // this.allOrderListApi();
        // //待付款
        // this.OneOrderIs = false;
        // this.OneOrderListApi();
        // //代发货
        // this.TwoOrderIs = false;
        // this.TwoOrderListApi();
        // //待收货
        // this.ThereOrderIs = false;
        // this.ThereOrderListApi();
        // //已完成
        // this.FourOrderIs = false;
        // this.FourOrderListApi();
        // //已取消
        // this.FiveOrderIs = false;
        // this.FiveOrderListApi();
    },
    methods: {
        // tabs通知swiper切换
        SwiperTabs(index) {
            this.Current = index;
        },
        refreshTab(tab) {
            if (this.currentTabName == tab.label) {
                console.log(this.$refs[tab.label])
                this.$refs[tab.label][0].getOrderList()
            }
            this.currentTabName = tab.label
        },
        onchange(e) {
            this.Current = e.detail.current;
        },
        //全部订单
        allOrderListApi() {
            const scope = this
            findBizShopGoodsOrder({
                orderStatus: ''
            }).then(res => {
                if (res.code === 0) {
                    scope.allOrderList = res.data.list.map(v => {
                        return {
                            ...v,
                            OrderText: scope.JudgeOrderState(v.orderStatus)
                        };
                    });
                    scope.allOrderIs = true;
                }
            });
        },
        //待付款
        OneOrderListApi() {
            const scope = this
            findBizShopGoodsOrder({
                orderStatus: '1'
            }).then(res => {
                if (res.code === 0) {
                    scope.OneOrderList = res.data.list;
                    scope.OneOrderIs = true;
                }
            });
        },
        //判断订单状态
        JudgeOrderState(num) {
            if (num == 1) {
                return '待支付';
            }
            if (num == 3) {
                return '';
            }
            if (num == 5 || num == 6 || num == 10) {
                return '实付款';
            }
        },
        //代发货
        TwoOrderListApi() {
            const scope = this
            findBizShopGoodsOrder({
                orderStatus: '5'
            }).then(res => {
                if (res.code === 0) {
                    scope.TwoOrderList = res.data.list;
                    scope.TwoOrderIs = true;
                }
            });
        },
        //待收货
        ThereOrderListApi() {
            const scope = this
            findBizShopGoodsOrder({
                orderStatus: '6'
            }).then(res => {
                if (res.code === 0) {
                    scope.ThereOrderList = res.data.list;
                    scope.ThereOrderIs = true;
                }
            });
        },
        //取消订单
        removeOrder(e) {
            const data = {
                id: e.orderId
            };
            const scope = this;
            uni.showModal({
                title: '温馨提示',
                content: '确认取消该订单',
                success(res) {
                    if (res.confirm) {
                        removeShopOrder(data).then(res => {
                            if (res.code === 0) {
                                scope.Current = 5;
                                //全部订单
                                scope.allOrderListApi();
                                //待付款
                                scope.OneOrderListApi();
                                //代发货
                                scope.TwoOrderListApi();
                                //待收货
                                scope.ThereOrderListApi();
                                //已完成
                                scope.FourOrderListApi();
                                //已取消
                                scope.FiveOrderListApi();
                            } else {}
                        }); //点击确定之后执行的代码
                    } else {
                        //点击取消之后执行的代码
                    }
                }
            });
        },
        IsShowReceipt(e) {
            this.OrderId = e.orderId;
            this.showReceipt = true;
        },
        TrueReceipt() {
            const scope = this
            confirmReceipt({
                id: this.OrderId
            }).then(res => {
                if (res.code === 0) {
                    scope.$refs.uToast.show({
                        title: res.msg
                    });
                    //全部订单
                    scope.allOrderListApi();
                    //待付款
                    scope.OneOrderListApi();
                    //代发货
                    scope.TwoOrderListApi();
                    //待收货
                    scope.ThereOrderListApi();
                    //已完成
                    scope.FourOrderListApi();
                    //已取消
                    scope.FiveOrderListApi();
                }
            });
        },
        //已完成
        FourOrderListApi() {
            const scope = this
            findBizShopGoodsOrder({
                orderStatus: '10'
            }).then(res => {
                if (res.code === 0) {
                    scope.FourOrderList = res.data.list;
                    scope.FourOrderIs = true;
                }
            });
        },
        IsShowInvoice(e) {
            const scope = this
            searchOrderInvoice({
                id: e.orderId
            }).then(res => {
                if (res.code === 0) {
                    if (res.data === null) {
                        scope.$refs.uToast.show({
                            title: res.msg
                        });
                    } else {
                        let InvoiceUrl = res.data.substring(0, res.data.lastIndexOf(','));
                        InvoiceUrl = InvoiceUrl.split(',');
                        var photos = InvoiceUrl; //是一个存放多张图片的数组
                        wx.previewImage({
                            current: photos, //当前图片地址
                            urls: photos, //所有要预览的图片的地址集合 数组形式
                            success: function(res) {},
                            fail: function(res) {},
                            complete: function(res) {}
                        });
                    }
                }
            });
        },
        //已取消
        FiveOrderListApi() {
            const scope = this
            findBizShopGoodsOrder({
                orderStatus: '3'
            }).then(res => {
                if (res.code === 0) {
                    scope.FiveOrderList = res.data.list;
                    scope.FiveOrderIs = true;
                }
            });
        },
        GoPayOrder(e) {
            const scope = this
            if (e.isExpire == 1) {
                this.$refs.uToast.show({
                    title: '当前商品已失效，不可购买'
                });
                return false;
            }
            let OrderFrom = {
                orderId: e.orderId,
                orderType: 2,
                openid: uni.getStorageSync('openId')
            };
            paymentAppPay(OrderFrom).then(res2 => {
                if (res2.code === 0) {
                    // TODO 修改支付调用接口
                    uni.requestPayment({
                        provider: 'wxpay',
                        timeStamp: res2.data.timeStamp,
                        nonceStr: res2.data.nonceStr,
                        package: res2.data.packages,
                        signType: 'RSA',
                        paySign: res2.data.paySign,
                        success: res3 => {
                            scope.$refs.uToast.show({
                                title: '支付成功'
                            });
                            //全部订单
                            scope.allOrderListApi();
                            //待付款
                            scope.OneOrderListApi();
                            //代发货
                            scope.TwoOrderListApi();
                            //待收货
                            scope.ThereOrderListApi();
                            //已完成
                            scope.FourOrderListApi();
                            //已取消
                            scope.FiveOrderListApi();
                            let myDate = new Date();
                            myDate.getYear(); //获取当前年份(2位)
                            myDate.getFullYear(); //获取完整的年份(4位,1970-????)
                            myDate.getMonth(); //获取当前月份(0-11,0代表1月)
                            myDate.getDate(); //获取当前日(1-31)
                            myDate.getDay(); //获取当前星期X(0-6,0代表星期天)
                            myDate.getTime(); //获取当前时间(从1970.1.1开始的毫秒数)
                            myDate.getHours(); //获取当前小时数(0-23)
                            myDate.getMinutes(); //获取当前分钟数(0-59)
                            myDate.getSeconds(); //获取当前秒数(0-59)
                            myDate.getMilliseconds(); //获取当前毫秒数(0-999)
                            myDate.toLocaleDateString(); //获取当前日期
                            let mytime = myDate.toLocaleString(); //获取日期与时间
                            let PaymentFrom = {
                                payWay: 1,
                                Money: e.paymentAmount,
                                mytime: mytime,
                                userName: uni.getStorageSync('nickName')
                            };
                            this.$router.push({
                                path: 'PaymentSuccee',
                                query: {
                                    PaymentFrom: encodeURIComponent(JSON.stringify(PaymentFrom))
                                }
                            })
                            // uni.navigateTo({
                            //     url: '/pagesC/PaymentSuccee/PaymentSuccee?PaymentFrom=' +
                            //         encodeURIComponent(JSON.stringify(PaymentFrom))
                            // });
                        },
                        fail: err => {
                            uni.showToast({
                                title: '支付失败',
                                icon: 'none',
                                duration: 3000
                            });
                        }
                    });
                }
            });
        },

        callPhone() {
            uni.makePhoneCall({
                phoneNumber: '029-85233571',
                success(e) {},
                fail(e) {}
            });
        },
        GoShoppingDetails(e) {
            this.$router.push({
                path: 'shoppingDetails',
				query: {
					orderId: e.orderId
				}
            })
            // uni.navigateTo({
            //     url: '/pagesA/MineShoppingOrder/shoppingDetails?orderId=' + e.orderId
            // });
        },

    }
};
</script>

<style lang="scss">
.MineShoppingOrder {
    .hairline-left {
        color: #f66a1d !important;
    }

    .PopupMobeil {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;

        .Top {
            text-align: center;

            .topUs {
                width: 750px;
                padding: 30px 30px;
                display: flex;
                align-items: center;

                .topUsTitle {
                    font-size: 16px;
                    font-weight: 700;
                    color: #000;
                    margin-right: 20px;
                }

                .topUsTitleCon {
                    font-size: 16px;
                    color: #999;
                }
            }
        }

        .consult {
            width: 750px;
            padding: 30px 30px;
            display: flex;
            justify-content: space-between;

            .leftIcon {
                width: 56px;
                height: 54px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .leftText {
                margin-left: 20px;
                text-align: left;
                display: flex;
                flex-direction: column;
                padding-bottom: 50px;

                .textTop {
                    height: 40px;
                    font-size: 16px;
                    color: #3d3d3d;
                    font-weight: 700;
                }

                .textBottom {
                    height: 40px;
                    font-size: 16px;
                    color: #3d3d3d;
                }
            }

            .consultRight {
                width: 324px;
                height: 160px;
                background-color: #f7f7f7;
                border-radius: 10px;
                display: flex;
                align-items: center;
                padding: 0 20px;

                .rightIcon {
                    width: 56px;
                    height: 54px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .rightText {
                    margin-left: 20px;

                    .textTop {
                        line-height: 40px;
                        font-size: 16px;
                        color: #3d3d3d;
                        font-weight: 700;
                    }

                    .textBottom {
                        line-height: 40px;
                        font-size: 16px;
                        color: #3d3d3d;
                    }
                }
            }
        }

        .backPhone {
            width: 90%;
            height: 70px;
            border-radius: 50px;
            text-align: center;
            line-height: 70px;
            color: #fff;
            background: linear-gradient(270deg, #f46720 0%, #f1491a 100%);
        }
    }

    .AllOrderList {
        width: 65%;
        height: 280px;
        display: flex;
        background-color: #ffffff;
        margin: 10px 0 20px 10%;
        border-radius: 10px;
        // pointer-events: none;

        .orderTag{
            margin-left: 30px;
            width: 92%;

            .shoppingHeader {
                display: flex;
                align-items: center;
                justify-content: space-between;
    
                .shoppingTitle {
                    height: 70px;
                    display: flex;
                    align-items: center;
    
                    .shoppingImage {
                        width: 18px;
                        height: 18px;
                        margin: 0 20px;
                    }
    
                    .shoppingName {
                        color: #3d3d3d;
                        font-weight: 600;
                        font-size: 16px;
                        // width: 504px;
                        display: -webkit-box;
                        overflow: hidden;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                    }
                }
    
                .goodsType_One_There {
                    font-size: 16px;
                    color: #f66a1d;
                    margin: 0 20px 0 0;
                }
    
                .goodsType_Four {
                    font-size: 16px;
                    color: #3d3d3d;
                    margin: 0 20px 0 0;
                }
    
                .goodsType_Five {
                    font-size: 16px;
                    color: #a8a8a8;
                    margin: 0 20px 0 0;
                }
            }
    
            .shoppingClass {
                padding: 0 0 10px 0;
                display: flex;
                // pointer-events: auto;
                cursor: pointer;
    
                .shoppingClassImage {
                    width: 120px;
                    height: 120px;
                    border-radius: 6px;
                    margin: 0 20px;
    
                    img {
                        max-width: 180px;
                        max-height: 180px;
                        border-radius: 6px;
                    }
                }
    
                .shoppingClassContent {
                    // width: 100%;
                    margin-left: 60px;
    
                    .ContentTitleNumber {
                        display: flex;
                        justify-content: space-between;
    
                        .ContentTitle {
                            width: 350px;
                            font-size: 16px;
                            color: #333333;
                            display: -webkit-box;
                            overflow: hidden;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
    
                        .ContentNumber {
                            font-size: 16px;
                            color: #808080;
                            margin: 0 20px 0 0;
                        }
                    }
    
                    .ContentSelect {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 30px;
    
                        // div:nth-child(1) {
                        //     // width: 200px;
                        //     display: -webkit-box;
                        //     overflow: hidden;
                        //     -webkit-line-clamp: 1;
                        //     -webkit-box-orient: vertical;
                        //     font-size: 14px;
                        //     color: #808080;
                        // }

                        // div:nth-child(2) {
                        //     display: -webkit-box;
                        //     overflow: hidden;
                        //     -webkit-line-clamp: 1;
                        //     -webkit-box-orient: vertical;
                        //     width: 60px;
                        //     height: 60px;
                        //     font-size: 14px;
                        //     color: #1d1717;
                        //     margin: 0 20px 0 0;
                        // }

                        .skuSpecs {
                            display: -webkit-box;
                            overflow: hidden;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            font-size: 14px;
                            color: #808080;
                        }
    
                        .skuPrice {
                            // width: 60px;
                            // height: 60px;
                            font-size: 14px;
                            color: #808080;
                            margin: 0 20px 0 0;
                        }
                    }
                }
            }
    
            .ActualPayment {
                text-align: right;
                font-size: 16px;
                font-weight: bold;
                color: #3d3d3d;
                margin: 0 20px 0 0;
            }
    
            .ButtonBox {
                display: flex;
                justify-content: flex-end;
                padding-bottom: 10px;
                margin: 10px 20px 0 0;
    
                .ButtonLeft {
                    width: 100px;
                    height: 40px;
                    margin-left: 20px;
                    border-radius: 20px;
                    border: 1px solid #ebebeb;
                    font-size: 16px;
                    color: #3d3d3d;
                    text-align: center;
                    line-height: 35px;
                    // pointer-events: auto;
                    cursor: pointer;
                }
    
                .ButtonRight {
                    width: 100px;
                    height: 40px;
                    margin-left: 20px;
                    border-radius: 20px;
                    border: 1px solid #f66a1d;
                    font-size: 16px;
                    color: #f66a1d;
                    text-align: center;
                    line-height: 35px;
                    // pointer-events: auto;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
