import axios from 'axios'
import { Notification, MessageBox, Message, Loading } from 'element-ui'
// import store from '@/store'
import errorCode from '@/request/errorCode'
import { tansParams } from "@/request/tansParams";
import {
  logout
} from "@/request/login";

// 是否显示重新登录

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  // baseURL: process.env.VUE_APP_BASE_URL,
  baseURL: process.env.VUE_APP_API,
  // 超时
  timeout: process.env.VUE_APP_BASE_TIME
})

// request拦截器
service.interceptors.request.use(config => {
  // const isToken = (config.headers || {}).isToken === false
  // if (!isToken) {
  // config.headers['Authorization'] = sessionStorage.getItem("token") // 让每个请求携带自定义token 请根据实际情况自行修改
  // }
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?' + tansParams(config.params);
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }

  return config
}, error => {
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
  // 未设置状态码则默认成功状态
  const code = res.data.code || 200;
  // 获取错误信息
  const msg = errorCode[code] || res.data.msg || errorCode['default']
  // 二进制数据则直接返回
  if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
    return res.data
  }
  if (code == 401) {
    MessageBox.confirm('登录状态已过期或未登录，请登录', '系统提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }
    ).then(() => {
      logout().then((res)=>{
        if(res.code == 200){
          sessionStorage.removeItem("token")
          location.href="/HomeView"
        }
      })
    }).catch(() => {
    });
  } else if (code === 500) {
    Message({
      message: msg,
      type: 'error'
    })
    return Promise.reject(new Error(msg))
  } else if (code !== 200) {
    // Message({
    //   message: msg,
    //   type: 'error'
    // })
    Notification.error({
      title: msg
    })
    return Promise.reject(new Error(msg))
  } else {
    return res.data
  }
},
  error => {
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    }
    else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    }
    else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Message({
      message: message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)


export default service
