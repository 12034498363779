<template>
  <div class="design">
    <iframe class="innerWin" :src="designUrl" style="width: 100%; height: 100%;"></iframe>
  </div>
</template>

<script>
import {getDicts} from "@/request/dict/data";
import {selectDictLabel, selectDictLabels} from "@/utils/weihua";

export default {
  name: "design",
  components: { },
  data() {
    return {
      designUrl: '/static/design/index.html',
      eventsList: [],
      eventStatusList: [],
      raceScheduleList: [],
    };
  },
  created() {

  },
  methods: {
    
  },
};
</script>
<style scoped>
.design {
  width: 100vw;
  height: 100vh;

  .innerWin {
    width: 100vw;
    height: 100vh;
  }

}

</style>