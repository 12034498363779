import request from '@/request/request'

const getAddressListUrl = "/system/info/address/list"                                       // 地址管理列表
const getAddressDetailUrl = "/system/info/address/getAddressInfo"                           // 地址管理详情
const getAddressAddUrl = "/system/info/address/add"                                         // 地址管理保存
const getAddressUpdataUrl = "/system/info/address/update"                                   // 地址管理编辑保存
const AddressRemoveUrl = "/system/info/address/remove"                                      // 地址管理删除

const findBizShopGoodsOrderUrl = "/bizShopGoodsOrderManage/findBizShopGoodsOrder"           // 查询商品订单列表
const findBizShopGoodsOrderDetailUrl = "/bizShopGoodsOrderManage/findBizShopGoodsOrderDetail"     // 查询商品订单列表
const paymentAppPayUrl = "/mp/third/wxPay/jsapi/v3/paymentAppPay"                           // 单订单支付
const confirmReceiptUrl = "/api/payment/confirmReceipt"                                     // 确认收货
const searchOrderInvoiceUrl = "/bizShopGoodsOrderManage/searchOrderInvoice"                 // 查看发票
const removeShopOrderUrl = "/api/payment/cancelOrder"                                       // 取消订单
const deleteOrderUrl = "/api/payment/deleteOrder"                                           // 删除订单记录

function baseCall(url, method, data) {
    return request({
        url: url,
        headers: {
            Authorization: sessionStorage.getItem("token")
        },
        method: method,
        data: data
    })
}

//个人资料
export function getUserData(userId) {
    return request({
        url: "/system/visitor/pc/"+userId,
        headers: {
            Authorization: sessionStorage.getItem("token")
        },
        method: "get",
    })
}
//个人资料保存
export function subUserData(data) {
    return request({
        url: "/system/visitor",
        headers: {
            Authorization: sessionStorage.getItem("token")
        },
        method: "put",
        data:data
    })
}
//修改密码提交

export function subPassWorld(data) {
    return request({
        url: "/system/user/changePassword",
        headers: {
            Authorization: sessionStorage.getItem("token")
        },
        method: "put",
        data:data
    })
}

//认证信息

export function getAuthInfo(userId) {
    return request({
        url: "/system/auth/getSettleInfo/"+userId,
        headers: {
            Authorization: sessionStorage.getItem("token")
        },
        method: "get",
    })
}
//我的赛事列表

export function getmyWorkList() {
    return request({
        url: "/system/production/myList",
        headers: {
            Authorization: sessionStorage.getItem("token")
        },
        method: "get",
    })
}

//消息列表接口
 
export function msgInfo(data) {
    return request({
        url: "/system/info/list",
        headers: {
            Authorization: sessionStorage.getItem("token")
        },
        method: "get",
        params:data
    })
}
//消息批量删除

export function msgRemove(data) {
    return request({
        url: "/system/info/batchRemove",
        headers: {
            Authorization: sessionStorage.getItem("token")
        },
        method: "post",
        data:data
    })
}

//消息一键已读

export function oneClickRead(data) {
    return request({
        url: "/system/info/oneClickRead",
        headers: {
            Authorization: sessionStorage.getItem("token")
        },
        method: "post",
        data:data
    })
}

//消息详情接口
export function msgDetail(id) {
    return request({
        url: "/system/info/"+id,
        headers: {
            Authorization: sessionStorage.getItem("token")
        },
        method: "get",
    })
}

// 地址管理列表
export function getAddressList(data) {
    return baseCall(getAddressListUrl, "post", data)
}

// 地址管理详情
export function getAddressDetail(data) {
    return baseCall(getAddressDetailUrl, "post", data)
}

// 地址管理保存
export function getAddressAdd(data) {
    return baseCall(getAddressAddUrl, "post", data)
}

// 地址管理编辑保存
export function getAddressUpdata(data) {
    return baseCall(getAddressUpdataUrl, "post", data)
}

// 地址管理删除
export function AddressRemove(data) {
    return baseCall(AddressRemoveUrl, "post", data)
}

// 查询商品订单列表
export function findBizShopGoodsOrder(data) {
    return baseCall(findBizShopGoodsOrderUrl, "post", data)
}

// 查询商品订单详情
export function findBizShopGoodsOrderDetail(data) {
    return baseCall(findBizShopGoodsOrderDetailUrl, "post", data)
}

// 单订单支付
export function paymentAppPay(data) {
    return baseCall(paymentAppPayUrl, "post", data)
}

// 确认收货
export function confirmReceipt(data) {
    return baseCall(confirmReceiptUrl, "post", data)
}

// 查看发票
export function searchOrderInvoice(data) {
    return baseCall(searchOrderInvoiceUrl, "post", data)
}

// 取消订单
export function removeShopOrder(data) {
    return baseCall(removeShopOrderUrl, "post", data)
}

export function deleteOrder(data) {
    return baseCall(deleteOrderUrl, "post", data)
}

