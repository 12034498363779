<template>
	<div class="shoppingDetails" ref="whole">
		<div class="DetailsTop">
			<div class="leftImage">
				<img src="@assets/image/orderDetails.png" mode=""/>
			</div>
			<div class="rightText" v-if="orderForm.orderStatus == 1 && orderForm.payWay == 1">
				<div class="rightTextTop">待付款</div>
				<div class="rightTextBottom" style="display: flex;align-items: center;">
					订单将在
					<!-- <text style="color: #F4631F;">{{timer}}</text> -->
					<!-- <yl-count-down :end-time="EndTime" @onOverTimeHander="onOverTimeHander" /> -->
					秒后取消
				</div>
			</div>
			<div class="rightText" v-if="orderForm.orderStatus == 1 && orderForm.payWay == 2">
				<div class="rightTextTop">待付款</div>
				<div class="rightTextBottom" style="display: flex;align-items: center;">请尽快支付您的订单</div>
			</div>
			<div class="rightText" v-if="orderForm.orderStatus == 5">
				<div class="rightTextTop">待发货</div>
				<div class="rightTextBottom">商家已经收到您的订单，正在准备发货中</div>
			</div>
			<div class="rightText" v-if="orderForm.orderStatus == 6">
				<div class="rightTextTop">待收货</div>
				<div class="rightTextBottom">商家已经发货，请您耐心等待</div>
			</div>
			<div class="rightText" v-if="orderForm.orderStatus == 10">
				<div class="rightTextTop">已完成</div>
				<div class="rightTextBottom">订单已交易完成</div>
			</div>
			<div class="rightText" v-if="orderForm.orderStatus == 3">
				<div class="rightTextTop">已取消</div>
			</div>
		</div>
		<div class="ReceivingAddress">
			<img class="image-bg" src="@assets/image/ReceivingAddress.png" />
			<div class="addressImage">
				<div class="imageBox">
					<img src="@assets/image/address.png" mode=""/>
				</div>
				<div class="addressContent">
					<div class="ContentOne">
						<div>{{ orderForm.receiptPersonName }}</div>
						<div>{{ orderForm.receiptPhone }}</div>
					</div>
					<div class="ContentTwo">{{ orderForm.detailArea }}{{ orderForm.detailAddress }}</div>
				</div>
			</div>
		</div>
		<div class="GoodsOrder">
			<div class="shoppingBox">
				<div class="shoppingTitle">
					<img class="shoppingImage" src="@assets/image/shopping.png" mode=""/>
					<div class="shoppingName">{{ orderForm.companyName }}</div>
				</div>
				<div class="shoppingClass" v-for="itemTwo in orderForm.detailList" :key="itemTwo.id">
					<div class="shoppingClassImage">
						<img :src="itemTwo.skuImg" mode="aspectFill"/>
					</div>
					<div class="shoppingClassContent">
						<div class="ContentSelect">
							<div>{{ itemTwo.goodsName }}</div>
							<div>X{{ itemTwo.goodsNum }}</div>
						</div>
						<div class="ItemMoney">
							<div>{{ itemTwo.skuSpecs }}</div>
							<div>￥{{ itemTwo.skuPrice }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="ClassBox" style="padding: 20px 0;" v-if="orderForm.remark">
			<div class="ClassItem">
				<div class="Item" style="border: 0;">
					<div class="ItemTop">备注</div>
					<div class="ItemBottom" style="width: 80%;">
						{{orderForm.remark}}
					</div>
				</div>
			</div>
		</div>
		<div class="ClassBox">
			<div style="font-size: 14px;font-weight: 600;color: #3d3d3d;padding: 18px 0 0 18px;">订单信息</div>
			<div class="ClassItem">
				<div class="Item">
					<div class="ItemTop">订单编号</div>
					<div class="ItemBottom">
						<div class="Left">{{ orderForm.shopOrderNo }}</div>
					</div>
				</div>
				<div class="Item">
					<div class="ItemTop">下单时间</div>
					<div class="ItemBottom">
						<div class="Left">{{ orderForm.orderTime }}</div>
					</div>
				</div>
				<div class="Item">
					<div class="ItemTop">支付方式</div>
					<div class="ItemBottom">
						<div class="Left">{{ orderForm.payWay == '1' ? '微信支付' : '对公账户' }}</div>
					</div>
				</div>
				<div class="Item" v-if="orderForm.orderStatus != 1 && orderForm.payTime">
					<div class="ItemTop">支付人</div>
					<div class="ItemBottom">
						<div class="Left">{{ orderForm.buyPersonName }}</div>
					</div>
				</div>
				<div class="Item" v-if="orderForm.orderStatus != 1 && orderForm.orderStatus != 3">
					<div class="ItemTop">支付时间</div>
					<div class="ItemBottom">
						<div class="Left">{{ orderForm.payTime }}</div>
					</div>
				</div>
				<div class="Item" v-if="orderForm.orderStatus == 3">
					<div class="ItemTop">取消时间</div>
					<div class="ItemBottom">
						<div class="Left">{{ orderForm.updateTime }}</div>
					</div>
				</div>
				<div class="Item" style="border: 0;" v-if="orderForm.payWay == 2">
					<div class="ItemTop">户名</div>
					<div class="ItemBottom">陕西博古领今信息科技有限公司</div>
				</div>
				<div class="Item" style="border: 0;" v-if="orderForm.payWay == 2">
					<div class="ItemTop">账户</div>
					<div class="ItemBottom">1032 9892 9190</div>
				</div>
				<div class="Item" style="border: 0;" v-if="orderForm.payWay == 2">
					<div class="ItemTop">开户行</div>
					<div class="ItemBottom">中国银行西安长安区富力城支行</div>
				</div>
				<div class="Item1" v-if="orderForm.payWay == 2">
					<!-- <u-alert-tips :show-icon="true" type="info" :description="description"></u-alert-tips> -->
				</div>
			</div>
		</div>
		<div class="ClassBox" v-if="orderForm.orderStatus == 6">
			<div style="font-size: 14px;font-weight: 600;color: #3d3d3d;padding: 18px 0 0 18px;">物流信息</div>
			<div class="ClassItem">
				<div class="Item">
					<div class="ItemTop">物流公司</div>
					<div class="ItemBottom">
						<div class="Left">{{ orderForm.logisticsCompany }}</div>
					</div>
				</div>
				<div class="Item" style="min-height: 70px;">
					<div class="ItemTop">物流单号</div>
					<div class="ItemBottom" style="width: 75%;">
						<div class="Left" style="width: 100%; word-wrap: break-word; text-align: right;">
							{{ orderForm.logisticsNo }}</div>
					</div>
				</div>
				<div class="Item">
					<div class="ItemTop">发货时间</div>
					<div class="ItemBottom">
						<div class="Left">{{ orderForm.deliverTime }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="ClassBox">
			<div style="font-size: 14px;font-weight: 600;color: #3d3d3d;padding: 18px 0 0 18px;">商品总额</div>
			<div class="ClassItem">
				<div class="Item">
					<div class="ItemTop">商品总金额</div>
					<div class="ItemBottom">
						<div class="Left">￥{{ orderForm.payableAmount }}</div>
					</div>
				</div>
				<div class="Item">
					<div class="ItemTop">运费</div>
					<div class="ItemBottom">
						<div class="Left">￥{{ orderForm.goodsFreight }}</div>
					</div>
				</div>
				<div class="Item">
					<div class="ItemTop">{{ OrderText }}</div>
					<div class="ItemBottom">
						<div class="Left" style="color: #F46520;font-weight: 600;">￥{{ orderForm.paymentAmount }}
						</div>
					</div>
				</div>
				<div class="Item1">
					<!-- <u-alert-tips :show-icon="true" type="info" :description="description"></u-alert-tips> -->
				</div>
			</div>
		</div>
		<div style="height: 100px;"></div>
		<div class="ButtonBox" v-show="fixed">
			<!-- <div class="ButtonLeft" v-if="orderForm.orderStatus == 10">查看发票</div> -->
			<div class="ButtonLeft" @click="showMobeilFun">咨询客服</div>
			<div class="ButtonRight" v-if="orderForm.orderStatus == 6" @click="IsShowReceipt(orderForm)">确认收货</div>
			<div class="ButtonRight" v-if="orderForm.orderStatus == 10" @click="IsShowInvoice(orderForm)">查看发票</div>
			<div class="ButtonLeft" @click="removeOrder(orderForm)" v-if="orderForm.orderStatus == 1">取消订单</div>
			<div class="ButtonRight" v-if="orderForm.orderStatus == 1" @click="GoPayOrder(orderForm)">付款</div>
			<div class="ButtonRight" v-if="orderForm.orderStatus >= 5 && orderForm.orderStatus < 15" @click="refundOrder(orderForm)">申请退款</div>
			<div class="ButtonLeft" @click="backToList">返回</div>
		</div>
		<!-- <u-modal v-model="showReceipt" content="请先确认订单是否到货？" :show-cancel-button="true" @confirm="TrueReceipt">
		</u-modal> -->
		<el-dialog
            title="联系客服"
            :show-close="false"
            width="400px"
            :visible.sync="showMobeil">
            <div class="inquiry">
              <div class="DialogTop">
                <div class="title">24小时客服电话</div>
                <div class="title1">029-85233571</div>
                <div class="img">
                  <img src="../../../assets/image/baojia03.png" alt="" />
                </div>
                <div class="bom">扫一扫添加客服微信</div>
              </div>
              <p class="colseBox" @click="showMobeil = false">
                <i class="el-icon-close"></i>
              </p>
            </div>
		</el-dialog>
		<!-- <u-popup mode="bottom" closeable="true" border-radius="15" width="630" height="360" v-model="showMobeil">
			<div class="PopupMobeil">
				<div class="Top">
					<div class="topUs">
						<div class="topUsTitle">咨询客服</div>
						<div class="topUsTitleCon">工作时间：工作日 9:00-18:00</div>
					</div>
				</div>
				<div class="consult">
					<u-button :custom-style="customStyle1" :hair-line="false" open-type="contact" hover-class="none"
						bindcontact="handleContact" send-message-path session-from="sessionFrom">
						<div class="leftIcon">
							<img src="@assets/icon/zixunerji.png" mode=""/>
						</div>
						<div class="leftText">
							<div class="textTop">在线客服</div>
							<div class="textBottom">及时解决您的问题</div>
						</div>
					</u-button>
					<div class="consultLeft"></div>
					<div class="consultRight" @click="callPhone">
						<div class="rightIcon">
							<img src="@assets/icon/zixunPhone.png" mode=""/>
						</div>
						<div class="rightText">
							<div class="textTop">电话客服</div>
							<div class="textBottom">029-85233571</div>
						</div>
					</div>
				</div>
			</div>
		</u-popup>
		<u-toast ref="uToast" /> -->
	</div>
</template>

<script>
import { getDicts } from '@/request/dict/data';
// import { throttle } from "@/static/js/throttle.js"; //引入节流函数
import {
	findBizShopGoodsOrderDetail,
	paymentAppPay,
	confirmReceipt,
	searchOrderInvoice,
	removeShopOrder
} from '@/request/mine.js';
import { payConfirm, submitRefund } from '@/request/shoppingGoods.js';
export default {
	data() {
		return {
			customStyle1: {
				width: '324px',
				height: '160px',
				backgroundColor: '#F7F7F7',
				borderRadius: '10px',
				display: 'flex',
				alignItems: 'center',
				padding: '0 20px',
				border: 'none'
			},
			description: '付款后，请电话联系平台客服，并提供转账凭证',
			showMobeil: false,
			orderForm: {},
			EndTime: '',
			time: 1800000,
			showReceipt: false,
			id: '',
			OrderId: '',
			OrderText: '',
			fixed: true,
			buttonOffset: 0,
			cancelReason: '',
		};
	},
	created() {
		getDicts('msk_sys_order_expire_time').then(res => {
			this.time = Number(res.data[0].dictValue) * 1000;
		});
		this.id = this.$route.query.orderId;
		this.findBizShopGoodsOrderDetailApi(this.id);
	},
	mounted() {
		window.addEventListener('scroll', this.fixActiveBtn)
		// console.log(this.$refs.whole.offsetHeight)
		this.buttonOffset = this.$refs.whole.offsetHeight + 700
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.fixActiveBtn)
	},
	methods: {
		fixActiveBtn(e) {
			// console.log('--------fixActiveBtn-----------')
			this.fixed = window.scrollY + this.$el.clientHeight < this.buttonOffset;
		},
		showMobeilFun() {
			this.showMobeil = true;
		},
		findBizShopGoodsOrderDetailApi(id) {
			findBizShopGoodsOrderDetail({
				orderId: id
			}).then(res => {
				if (res.code === 0) {
					payConfirm({id: id}).then(res2 => {
						if (res2.data.tradeStatus && 'S' == res2.data.tradeStatus) {
							// 已支付
						}
						this.orderForm = res.data.orderDetail;
						if (this.orderForm.orderStatus == 1) {
							this.OrderText = '待支付';
						}
						if (this.orderForm.orderStatus == 3) {
							this.OrderText = '订单金额';
						}
						if (this.orderForm.orderStatus == 5 || this.orderForm.orderStatus == 6 || this.orderForm
							.orderStatus == 10) {
							this.OrderText = '实付款';
						}
						if (this.orderForm.orderStatus == 1) {
							let date = new Date(this.orderForm.orderTime);
							this.EndTime = date.getTime() + this.time;
						}
					})
				}
			});
		},
		//取消订单
		removeOrder(e) {
			const data = {
				id: e.orderId
			};
			let scope = this;
			this.$confirm("确认取消该订单?", "温馨提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			}).then(() => {
				removeShopOrder(data).then(res => {
					if (res.code === 0) {
						scope.findBizShopGoodsOrderDetailApi(scope.id);
					} else {
						this.$router.push({
							path: 'myShoppingOrder'
						})
					}
				}); //点击确定之后执行的代码
			}).catch(() => {});

			// uni.showModal({
			// 	title: '温馨提示',
			// 	content: '确认取消该订单',
			// 	success(res) {
			// 		if (res.confirm) {
			// 			removeShopOrder(data).then(res => {
			// 				if (res.code === 0) {
			// 					that.findBizShopGoodsOrderDetailApi(that.id);
			// 				} else {}
			// 			}); //点击确定之后执行的代码
			// 		} else {
			// 			//点击取消之后执行的代码
			// 		}
			// 	}
			// });
		},
		// onOverTimeHander() {
		// 	uni.navigateBack();
		// },
		GoPayOrder(order) {
			if (order.isExpire == 1) {
				this.$message({
					message: "当前商品已失效，不可购买",
					type: "warning",
				})
				return false;
			}
			let orderStr = []
			orderStr.push(order)
			// 跳转至支付界面
			this.$router.push({
				name: "payMent", 
				query: {
					orderStr: orderStr,
					hasOrder: true
				}
			})
		},


		IsShowReceipt(e) {
			this.OrderId = e.orderId;
			this.showReceipt = true;
            this.TrueReceipt(this.OrderId)
		},

		TrueReceipt(OrderId) {
            const scope = this
            this.$confirm("确认您已收到该商品？", "温馨提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			}).then(() => {
				confirmReceipt({
                    id: OrderId
                }).then(res => {
                    if (res.code === 0) {
                        scope.$message({
                            message: "确认到货成功",
                            type: "success",
                        });
                        scope.findBizShopGoodsOrderDetailApi(scope.id);
                    } else {
                        scope.$message({
                            message: "确认到货失败",
                            type: "warning",
                        });
                    }
                })
			}).catch(() => {})
        },
		
		callPhone() {
			uni.makePhoneCall({
				phoneNumber: '029-85233571',
				success(e) {},
				fail(e) {}
			});
		},
		
		IsShowInvoice(e) {
            const scope = this
            searchOrderInvoice({
                id: e.orderId
            }).then(res => {
                if (res.code === 0) {
                    if (res.data === null) {
                        scope.$message({
                            message: res.msg,
                            type: "warning",
                        })
                    } else {
                        let InvoiceUrl = res.data.substring(0, res.data.lastIndexOf(','));
                        InvoiceUrl = InvoiceUrl.split(',');
                        var photos = InvoiceUrl; //是一个存放多张图片的数组
                        // TODO 预览发票及下载
                        
                        // wx.previewImage({
                        //     current: photos, //当前图片地址
                        //     urls: photos, //所有要预览的图片的地址集合 数组形式
                        //     success: function(res) {},
                        //     fail: function(res) {},
                        //     complete: function(res) {}
                        // });
                    }
                }
            });
        },

		refundOrder(e) {
			const scope = this
            this.$confirm("确定要申请退款？", "温馨提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			}).then(() => {
				submitRefund({
                    id: e.orderId,
					cancelReason: scope.cancelReason
                }).then(res => {
                    if (res.code === 0) {
                        scope.$message({
                            message: "申请退款成功，请等待商家审核",
                            type: "success",
                        });
                        scope.findBizShopGoodsOrderDetailApi(scope.id);
                    } else {
                        scope.$message({
                            message: "申请退款失败",
                            type: "warning",
                        });
                    }
                })
			}).catch(() => {})
			
		},

		backToList() {
			this.$router.push({ path: 'myShoppingOrder' })
		},
	}
};
</script>

<style lang="scss">
page {
	background: #f7f7f7;
}

.shoppingDetails {
	.PopupMobeil {
		background-color: #fff;
		display: flex;
		flex-direction: column;
		align-items: center;

		.Top {
			text-align: center;

			.topUs {
				width: 750px;
				padding: 30px 30px;
				display: flex;
				align-items: center;

				.topUsTitle {
					font-size: 14px;
					font-weight: 700;
					color: #000;
					margin-right: 20px;
				}

				.topUsTitleCon {
					font-size: 14px;
					color: #999;
				}
			}
		}

		.consult {
			width: 750px;
			padding: 30px 30px;
			display: flex;
			justify-content: space-between;

			.leftIcon {
				width: 56px;
				height: 54px;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.leftText {
				margin-left: 20px;
				text-align: left;
				display: flex;
				flex-direction: column;
				padding-bottom: 50px;

				.textTop {
					height: 40px;
					font-size: 14px;
					color: #3d3d3d;
					font-weight: 700;
				}

				.textBottom {
					height: 40px;
					font-size: 14px;
					color: #3d3d3d;
				}
			}

			.consultRight {
				width: 324px;
				height: 160px;
				background-color: #f7f7f7;
				border-radius: 10px;
				display: flex;
				align-items: center;
				padding: 0 20px;

				.rightIcon {
					width: 56px;
					height: 54px;

					img {
						width: 100%;
						height: 100%;
					}
				}

				.rightText {
					margin-left: 20px;

					.textTop {
						line-height: 40px;
						font-size: 14px;
						color: #3d3d3d;
						font-weight: 700;
					}

					.textBottom {
						line-height: 40px;
						font-size: 14px;
						color: #3d3d3d;
					}
				}
			}
		}

		.backPhone {
			width: 90%;
			height: 70px;
			border-radius: 50px;
			text-align: center;
			line-height: 70px;
			color: #fff;
			background: linear-gradient(270deg, #f46720 0%, #f1491a 100%);
		}
	}

	.DetailsTop {
		width: 80%;
		height: 80px;
		background: #ffffff;
		display: flex;
		margin: 30px 0px 10px 40px;
		border-radius: 10px;

		.leftImage {
			width: 28px;
			height: 28px;
			margin: 20px 15px 20px 20px;

			img {
				width: 28px;
				height: 28px;
			}
		}

		.rightText {
			margin-top: 15px;

			.rightTextTop {
				font-size: 14px;
				font-weight: bold;
				color: #f4631f;
			}

			.rightTextBottom {
				margin-top: 10px;
				font-size: 14px;
				color: #999999;
			}
		}
	}

	.ReceivingAddress {
		position: relative;
		width: 80%;
		height: 90px;
		margin: 10px 0px 10px 40px;
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: #ffffff;

		.image-bg {
			position: absolute;
			z-index: 1;
			right: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}

		.addressImage {
			display: flex;
			z-index: 2;

			.imageBox {
				margin: 5px 10px 0px 25px;
				width: 24px;
				height: 28px;

				img {
					width: 18px;
					height: 20px;
				}
			}

			.addressContent {
				.ContentOne {
					font-size: 14px;
					font-weight: bold;
					color: #3d3d3d;
				}

				.ContentTwo {
					font-size: 14px;
					font-weight: normal;
					color: #999999;
					margin-top: 5px;
				}
			}
		}
	}

	.GoodsOrder {
		width: 80%;
		border-radius: 10px;
		background-color: #ffffff;
		margin: 10px 0px 10px 40px;

		.shoppingBox {
			width: 80%;
			background-color: #ffffff;
			margin: 0px 0px 0px 50px;
			border-radius: 10px;
			border-bottom: 1px solid #ebebeb;

			.shoppingTitle {
				height: 50px;
				display: flex;
				align-items: center;

				.shoppingImage {
					width: 20px;
					height: 20px;
					margin: 0 20px;
				}

				.shoppingName {
					color: #3d3d3d;
					font-size: 14px;
				}
			}

			.shoppingClass {
				padding: 0px 0px 30px 0px;
				display: flex;

				.shoppingClassImage {
					width: 120px;
					height: 120px;
					border-radius: 6px;
					margin: 0 20px;

					img {
						width: 120px;
						height: 120px;
						border-radius: 6px;
					}
				}

				.shoppingClassContent {
					width: 100%;

					.ContentSelect {
						display: flex;
						justify-content: space-between;
						height: 80px;

						view:nth-child(1) {
							width: 440px;
							font-size: 14px;
							color: #333333;
							word-break: break-all;
							display: -webkit-box;
							overflow: hidden;
							line-height: 40px;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
						}

						view:nth-child(2) {
							font-size: 14px;
							margin-right: 30px;
							color: #808080;
						}
					}

					.ItemMoney {
						font-size: 14px;
						color: #808080;
						display: flex;
						justify-content: space-between;

						view:nth-child(2) {
							color: #3d3d3d;
							margin-right: 30px;
						}
					}
				}
			}
		}
	}

	.ClassBox {
		width: 80%;
		background: #ffffff;
		border-radius: 10px;
		margin: 10px 0px 10px 40px;

		.ClassItem {
			width: 630px;
			margin: 0px 0px 10px 120px;

			.Item1 {
				padding: 10px 0 10px 0;
			}

			.Item {
				height: 30px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.ItemTop {
					font-size: 14px;
					font-weight: 400;
					color: #3d3d3d;
				}

				.ItemBottom {
					display: flex;
					justify-content: space-between;

					.Left {
						font-size: 14px;
						color: #3d3d3d;
					}
				}
			}
		}
	}

	.ButtonBox {
		height: 400px;
		width: 70.5%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items:flex-end;
		// direction: ;
		// height: 100px;
		// background-color: #ffffff;
		z-index: 666;
		position: fixed;
		bottom: 60px;

		.ButtonLeft {
			width: 100px;
			height: 60px;
			right: 0px;
			border-radius: 100px;
			background-color: #ffffff;
			border: 1px solid #ebebeb;
			font-size: 14px;
			color: #3d3d3d;
			text-align: center;
			line-height: 55px;
			margin-top: 20px;
			margin-right: 20px;
			cursor: pointer;
		}

		.ButtonRight {
			width: 100px;
			height: 60px;
			border-radius: 100px;
			background-color: #ffffff;
			border: 1px solid #f66a1d;
			font-size: 14px;
			color: #f66a1d;
			text-align: center;
			line-height: 55px;
			margin-top: 20px;
			margin-right: 20px;
			cursor: pointer;
		}
	}

	.inquiry {
      width: 347px;
      height: 401px;
      .DialogTop {
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 30px;
        }
        .title1 {
          font-size: 30px;
          font-family: DIN;
          font-weight: 500;
          color: #f66a1d;
          line-height: 50px;
        }
        .img {
          width: 258px;
          height: 258px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .bom {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          line-height: 20px;
        }
      }
      .colseBox {
        cursor: pointer;
        position: absolute;
        top: 4px;
        right: -30px;
        border: 1px solid #bdbdbd;
        border-radius: 50%;
        i {
          font-size: 24px;
          color: #bdbdbd;
        }
      }
    }
}
</style>
