<template>
	<div class="baseOrderList">
        <!-- <div>
            <i class="el-icon-refresh"></i>
        </div> -->
        <div class="AllOrderList" v-for="itemOne in orderList" :key="itemOne.orderId">
            <div class="orderTag">
                <div class="shoppingHeader">
                    <div class="shoppingTitle">
                        <img class="shoppingImage" src="@assets/image/shopping.png" mode=""/>
                        <div class="shoppingName">{{ itemOne.companyName }}</div>
                    </div>
                    <div class="goodsType_One_There" v-if="itemOne.orderStatus == 1">待付款</div>
                    <div class="goodsType_One_There" v-if="itemOne.orderStatus == 5">待发货</div>
                    <div class="goodsType_One_There" v-if="itemOne.orderStatus == 6">待收货</div>
                    <div class="goodsType_Four" v-if="itemOne.orderStatus == 10">已完成</div>
                    <div class="goodsType_Five" v-if="itemOne.orderStatus == 3">已取消</div>
                    <div class="goodsType_Five" v-if="itemOne.orderStatus == 15">待退款</div>
                    <div class="goodsType_Five" v-if="itemOne.orderStatus == 20">已退款</div>
                </div>
                <div class="shoppingClass" v-for="itemTwo in itemOne.detailList"
                        :key="itemTwo.goodsId" @click="GoShoppingDetails(itemOne)">
                    <div class="shoppingClassImage">
                        <img :src="itemTwo.skuImg" mode="aspectFill"/>
                    </div>
                    <div class="shoppingClassContent">
                        <div class="ContentTitleNumber">
                            <div class="ContentTitle">{{ itemTwo.goodsName }}</div>
                            <div class="ContentNumber">X{{ itemTwo.goodsNum }}</div>
                        </div>
                        <div class="ContentSelect">
                            <div class="skuSpecs">{{ itemTwo.skuSpecs }}</div>
                            <div class="skuPrice">
                                ￥{{ itemTwo.skuPrice }}
                                <!-- <text >￥</text>
                                <text>{{ itemTwo.skuPrice }}</text> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ActualPayment">{{ itemOne.OrderText }} ¥{{ itemOne.paymentAmount }}</div>
                <div class="ButtonBox">
                    <!-- <div class="ButtonLeft" @click="showMobeil = true">咨询客服</div> -->
                    <div class="ButtonRight" v-if="itemOne.orderStatus == 5 || itemOne.orderStatus == 6"
                        @click="IsShowReceipt(itemOne)">确认收货</div>
                    <div class="ButtonLeft" @click="IsShowInvoice(itemOne)" v-if="itemOne.orderStatus == 10">查看发票</div>
                    <div class="ButtonLeft" @click="removeOrder(itemOne)" v-if="itemOne.orderStatus == 1">取消订单</div>
                    <div class="ButtonRight" v-if="itemOne.orderStatus == 1" @click="GoPayOrder(itemOne)">
                        付款
                    </div>
                    <div class="ButtonRight" v-if="itemOne.orderStatus == 3 || itemOne.orderStatus == 10 || itemOne.orderStatus == 20"
                        @click="deleteOrder(itemOne)">
                        删除
                    </div>
                </div>
            </div>
        </div>
        <!-- <div style="margin-top: 30px ;" v-if="orderList.length !== 0">
            <el-loadmore status="nomore" />
        </div> -->
        <div class="noListData" v-if="orderList.length == 0">
            <div class="noImg">
                <img src="@assets/image/nomarll.png" mode=""/>
            </div>
            <div class="noText">暂无数据</div>
        </div>
	</div>
</template>

<script>
import {
    findBizShopGoodsOrder,
    paymentAppPay,
    confirmReceipt,
    searchOrderInvoice,
    removeShopOrder,
    deleteOrder,
} from '@/request/mine.js';
export default {
    props: {
        // orderList: {
        //     type: Array,
        //     default() {
        //         return []
        //     }
        // },
        orderTypeInfo: {
            type: Object,
            default() {
                return {}
            }
        },
        refreshFunc: {
            type: Function,
            default() {
                return () => {}
            }
        },
    },
    data() {
        return {
            labelName: this.orderTypeInfo.name,
            orderStatus: this.orderTypeInfo.orderStatus,
            orderList: [],
            // customStyle1: {
            //     width: '324px',
            //     height: '160px',
            //     backgroundColor: '#F7F7F7',
            //     borderRadius: '10px',
            //     display: 'flex',
            //     alignItems: 'center',
            //     padding: '0 20px',
            //     border: 'none'
            // },
            showMobeil: false,
            // Current: 0,
            // tabsList: [{
            //     key: 1,
            //     name: '全部订单'
            // }, {
            //     key: 2,
            //     name: '待付款'
            // }, {
            //     key: 3,
            //     name: '待发货'
            // }, {
            //     key: 4,
            //     name: '待收货'
            // }, {
            //     key: 5,
            //     name: '已完成'
            // }, {
            //     key: 6,
            //     name: '已取消'
            // }],
            OrderId: ''
        };
    },
    created() {
        // console.log('--------list---------')
        // console.log(this.labelName + '-----' + this.orderStatus)
        // console.log(this.orderTypeInfo)
        this.getOrderList()
    },
    methods: {
        // tabs通知swiper切换
        // SwiperTabs(index) {
        //     this.Current = index;
        // },
        // onchange(e) {
        //     this.Current = e.detail.current;
        // },
        
        getOrderList() {
            const scope = this
            findBizShopGoodsOrder({
                orderStatus: scope.orderStatus == 0 ? '' : ('' + scope.orderStatus)
            }).then(res => {
                if (res.code === 0) {
                    scope.orderList = res.data.list.map(v => {
                        return {
                            ...v,
                            OrderText: scope.JudgeOrderState(v.orderStatus)
                        };
                    });
                }
            });
        },

        //判断订单状态
        JudgeOrderState(num) {
            if (num == 1) {
                return '待支付';
            }
            if (num == 3) {
                return '';
            }
            if (num == 5 || num == 6 || num == 10) {
                return '实付款';
            }
        },

        //取消订单
        removeOrder(e) {
            const data = {
                id: e.orderId
            };
            const scope = this;

            this.$confirm("确认取消该订单", "温馨提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			}).then(() => {
				removeShopOrder(data).then(res => {
                    if (res.code === 0) {
                        scope.$message({
                            message: "取消订单成功",
                            type: "success",
                        });
                        scope.getOrderList()
                    } else {
                        scope.$message({
                            message: "取消订单失败",
                            type: "warning",
                        });
                    }
                })
			}).catch(() => {})
        },
        IsShowReceipt(e) {
            const OrderId = e.orderId
            this.showReceipt = true
            this.TrueReceipt(OrderId)
        },
        TrueReceipt(OrderId) {
            const scope = this
            this.$confirm("确认您已收到该商品？", "温馨提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			}).then(() => {
				confirmReceipt({
                    id: OrderId
                }).then(res => {
                    if (res.code === 0) {
                        scope.$message({
                            message: "确认到货成功",
                            type: "success",
                        });
                        scope.getOrderList()
                    } else {
                        scope.$message({
                            message: "确认到货失败",
                            type: "warning",
                        });
                    }
                })
			}).catch(() => {})
        },
        
        IsShowInvoice(e) {
            const scope = this
            searchOrderInvoice({
                id: e.orderId
            }).then(res => {
                if (res.code === 0) {
                    if (res.data === null) {
                        scope.$message({
                            message: res.msg,
                            type: "warning",
                        })
                    } else {
                        let InvoiceUrl = res.data.substring(0, res.data.lastIndexOf(','));
                        InvoiceUrl = InvoiceUrl.split(',');
                        var photos = InvoiceUrl; //是一个存放多张图片的数组
                        // TODO 预览发票及下载

                        // wx.previewImage({
                        //     current: photos, //当前图片地址
                        //     urls: photos, //所有要预览的图片的地址集合 数组形式
                        //     success: function(res) {},
                        //     fail: function(res) {},
                        //     complete: function(res) {}
                        // });
                    }
                }
            });
        },
        
        GoPayOrder(e) {
            const scope = this
            if (e.isExpire == 1) {
                this.$message({
					message: "当前商品已失效，不可购买",
					type: "warning",
				})
				return false;
            }
            let orderStr = []
			orderStr.push(e)
			// 跳转至支付界面
			this.$router.push({
				name: "payMent", 
				query: {
					orderStr: orderStr,
					hasOrder: true
				}
			})
        },

        deleteOrder(e) {
            const scope = this
            this.$confirm("确定删除该条交易记录？", "温馨提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			}).then(() => {
                deleteOrder({id: e.orderId}).then(res => {
                    if (res.code === 0) {
                        scope.$message({
                            message: "删除成功",
                            type: "success",
                        });
                        scope.getOrderList()
                    } else {
                        scope.$message({
                            message: "删除失败",
                            type: "warning",
                        });
                    }
                })
			}).catch(() => {})
            
        },

        callPhone() {
            uni.makePhoneCall({
                phoneNumber: '029-85233571',
                success(e) {},
                fail(e) {}
            });
        },
        GoShoppingDetails(e) {
            this.$router.push({
                path: 'orderDetail',
				query: {
					orderId: e.orderId
				}
            })
            // uni.navigateTo({
            //     url: '/pagesA/MineShoppingOrder/shoppingDetails?orderId=' + e.orderId
            // });
        },

    }
};
</script>

<style lang="scss">
.baseOrderList {

    // .AllOrderList {
    //     width: 100%;
    //     display: flex;
    //     background-color: #ffffff;
    //     margin: 10px 0 20px 10%;
    //     border-radius: 10px;

    //     .orderTag{
    //         // width: 48%;

    //         .shoppingHeader {
    //             display: flex;
    //             align-items: center;
    //             justify-content: space-between;

    //             .shoppingTitle {
    //                 height: 70px;
    //                 display: flex;
    //                 align-items: center;

    //                 .shoppingImage {
    //                     width: 18px;
    //                     height: 18px;
    //                     margin: 0 20px;
    //                 }

    //                 .shoppingName {
    //                     color: #3d3d3d;
    //                     font-weight: 600;
    //                     font-size: 16px;
    //                     // width: 504px;
    //                     display: -webkit-box;
    //                     overflow: hidden;
    //                     -webkit-line-clamp: 1;
    //                     -webkit-box-orient: vertical;
    //                 }
    //             }

    //             .goodsType_One_There {
    //                 font-size: 16px;
    //                 color: #f66a1d;
    //                 margin: 0 20px 0 0;
    //             }

    //             .goodsType_Four {
    //                 font-size: 16px;
    //                 color: #3d3d3d;
    //                 margin: 0 20px 0 0;
    //             }

    //             .goodsType_Five {
    //                 font-size: 16px;
    //                 color: #a8a8a8;
    //                 margin: 0 20px 0 0;
    //             }
    //         }

    //         .shoppingClass {
    //             padding: 0 0 10px 0;
    //             display: flex;

    //             .shoppingClassImage {
    //                 width: 120px;
    //                 height: 120px;
    //                 border-radius: 6px;
    //                 margin: 0 20px;

    //                 img {
    //                     max-width: 120px;
    //                     max-height: 120px;
    //                     border-radius: 6px;
    //                 }
    //             }

    //             .shoppingClassContent {
    //                 // width: 100%;

    //                 .ContentTitleNumber {
    //                     display: flex;
    //                     justify-content: space-between;

    //                     .ContentTitle {
    //                         // width: 354px;
    //                         font-size: 16px;
    //                         color: #333333;
    //                         display: -webkit-box;
    //                         overflow: hidden;
    //                         -webkit-line-clamp: 2;
    //                         -webkit-box-orient: vertical;
    //                     }

    //                     .ContentNumber {
    //                         font-size: 16px;
    //                         color: #808080;
    //                         margin: 0 20px 0 0;
    //                     }
    //                 }

    //                 .ContentSelect {
    //                     display: flex;
    //                     align-items: center;
    //                     justify-content: space-between;
    //                     margin-top: 30px;

    //                     view:nth-child(1) {
    //                         // width: 200px;
    //                         display: -webkit-box;
    //                         overflow: hidden;
    //                         -webkit-line-clamp: 1;
    //                         -webkit-box-orient: vertical;
    //                         font-size: 16px;
    //                         color: #808080;
    //                     }

    //                     view:nth-child(2) {
    //                         font-size: 16px;
    //                         color: #3d3d3d;
    //                         margin: 0 20px 0 0;
    //                     }
    //                 }
    //             }
    //         }

    //         .ActualPayment {
    //             text-align: right;
    //             font-size: 16px;
    //             font-weight: bold;
    //             color: #3d3d3d;
    //             margin: 0 20px 0 0;
    //         }

    //         .ButtonBox {
    //             display: flex;
    //             justify-content: flex-end;
    //             padding-bottom: 10px;
    //             margin: 10px 20px 0 0;

    //             .ButtonLeft {
    //                 // width: 150px;
    //                 height: 40px;
    //                 border-radius: 20px;
    //                 border: 1px solid #ebebeb;
    //                 font-size: 16px;
    //                 color: #3d3d3d;
    //                 text-align: center;
    //                 line-height: 35px;
    //             }

    //             .ButtonRight {
    //                 // width: 150px;
    //                 height: 40px;
    //                 border-radius: 20px;
    //                 border: 1px solid #f66a1d;
    //                 font-size: 16px;
    //                 color: #f66a1d;
    //                 text-align: center;
    //                 line-height: 35px;
    //             }
    //         }
    //     }
    // }
}
</style>
