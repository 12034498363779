<template>
	<div class="newAddress">
		<div class="title">
			{{ title }}
		</div>
		<div class="form">
			<el-form :model="form" ref="uForm" :rules="rules" :error-type="errorType" label-width="120px">
				<el-form-item label="收件人" :required="true"  prop="receiptPersonName">
					<el-input maxlength="8" placeholder="请输入收件人" v-model="form.receiptPersonName" />
				</el-form-item>
				<el-form-item label="联系电话" :required="true" prop="receiptPhone">
					<el-input maxlength="11" placeholder="请输入手机号码"  v-model="form.receiptPhone" type="number" />
				</el-form-item>
				<el-form-item label="省市区" prop="detailArea" style="" >
					<el-cascader style=""
						placeholder="请选择所在城市"
						v-model="selectedOptions"
						:options="options"
						clearable
						filterable
						@change="handleChange" >
					</el-cascader>
				</el-form-item>
				<el-form-item label="详细地址" :required="true" prop="detailAddress">
					<el-input v-model="form.detailAddress" maxlength="50" placeholder="如道路，门牌号，楼栋号，单元室等" />
				</el-form-item>
				<el-form-item label="是否设为默认" >
					<el-switch v-model="checked" active-color="#2AEA77" size="40"></el-switch>
				</el-form-item>
			</el-form>
		</div>
		<div>
			<el-button class="saveBtn" type="primary" @click="backMineAddress" >
				保存
			</el-button>
			<el-button class="cancleBtn" @click="cancleEdit" >
				取消
			</el-button>
		</div>
	</div>
</template>

<script>
	import { getAddressList, getAddressDetail, getAddressAdd, getAddressUpdata, AddressRemove } from "@/request/mine.js";
	import { regionData, CodeToText, TextToCode, } from "element-china-area-data";
	export default {
		props: {
			isDialog: {
				type: Boolean,
				default() {
					return false
				}
			},
			callbackConfirm: {
				type: Function,
				default() {
					return () => {}
				}
			},
			callbackCancle: {
				type: Function,
				default() {
					return () => {}
				}
			}
		},
		data() {
			return {
				isEdit: false,
				title: '新增收货地址',
				errorType: ['message'],
				showCity: false,
				checked: false,
      			selectedOptions: [],
      			options: regionData,
				form: {
					receiptPersonName: null,
					receiptPhone: null,
					detailArea: null,
					detailAddress: null,
					isFault: null,
				},
				rules: {
					receiptPersonName: [{
							min: 2,
							required: true,
							message: '请输入收件人姓名',
							trigger: ['change', 'blur']
						},
						{
							validator: function(rule, value, callback) {
								var regex = /^[a-zA-Z\u4e00-\u9fa5]+$/;
								if (!regex.test(value)) {
									callback(new Error("请输入正确格式的姓名"));
								} else {
									callback();
								}
							},
							trigger: ['change', 'blur'],
						},
					],
					receiptPhone: [{
							required: true,
							message: '请输入联系电话',
							trigger: ['change', 'blur'],
						},
						{
							pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
							message: "请输入正确的手机号码",
							trigger: ['change', 'blur'],
						},
					],
					detailArea: [{
						required: true,
						message: '请选择所在城市',
						trigger: ['change', 'blur']
					}],
					detailAddress: [{
						required: true,
						message: '请输入您的详细地址',
						trigger: ['change', 'blur']
					}]
				}
			}
		},
		created() {
			if (this.$route.query.edit) {
				this.isEdit = true
				this.title = '修改收货地址'
				this.getAddressDetailApi(this.$route.query.id)
			}
		},
		mounted() {
		},
		methods: {
			getAddressDetailApi(id) {
				const scope = this
				getAddressDetail({id: id}).then((res) => {
					scope.form = res.data
					if (res.data.isFault == 1) {
						scope.checked = true
					} else {
						scope.checked = false
					}
					scope.setSelectedOptions();
				})
			},
			hilheShowCity(e) {
				this.form.detailArea = e.province.label + '-' + e.city.label + '-' + e.area.label;
			}, 
			backMineAddress() {
				const scope = this
				this.$refs.uForm.validate(valid => {
					if (valid) {
						if (this.checked) {
							this.form.isFault = 1
						} else {
							this.form.isFault = 0
						}
						if (this.isEdit) {
							getAddressUpdata(this.form).then((res) => {
								// TODO 添加新增成功消息提示
								if (scope.isDialog) {
									scope.callbackConfirm()
								} else {
									this.$router.push({path: 'mineAddress'})
								}
							})
						} else {
							getAddressAdd(this.form).then((res) => {
								// TODO 添加新增成功消息提示
								if (scope.isDialog) {
									scope.callbackConfirm()
								} else {
									this.$router.push({path: 'mineAddress'})
								}
							})
						}
					}
				});
			},
			cancleEdit() {
				if (this.isDialog) {
					this.callbackCancle()
				} else {
					this.$router.push({path: 'mineAddress'})
				}
			},
			/** 地区回填 */
			setSelectedOptions() {
				if (this.form.detailArea) {
					//重置this.selectedOptions
					this.selectedOptions = [];
					let address = this.form.detailArea.split("-");
					// 省份
					this.selectedOptions.push(TextToCode[address[0]].code);
					// 城市
					let cityCode = TextToCode[address[0]][address[1]].code;
					this.selectedOptions.push(cityCode);
					// 地区
					let areaCode = TextToCode[address[0]][address[1]][address[2]].code;
					this.selectedOptions.push(areaCode);
				}
			},
			//省市区选择后，form表单数据保存
			handleChange(value) {
				var loc = "";
				var code = "";
				loc +=
					CodeToText[this.selectedOptions[0]] +
					"-" +
					CodeToText[this.selectedOptions[1]] +
					"-" +
					CodeToText[this.selectedOptions[2]];
				this.form.detailArea = loc;
				this.form.deptAreaCode = code;
			},
		}
	}
</script>

<style lang="scss" scoped>
	.newAddress {
		width: 60%;
		height: 100vh;
		// background-color: #f7f7f7;
		padding: 20px 26px;

		.title {
			padding: 0 0 20px 30px;
			margin-bottom: 10px;
			font-size: 16px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #333333;
			border-bottom: 1px solid #ebebeb;
		}

		.form {
			margin-left: 100px;
			padding: 0 20px;
			border-radius: 20px;
			// background-color: #fff;

			.newAddressTitle {
				height: 70px;
				line-height: 70px;
				font-size: 30px;
				color: #3D3D3D;
				font-weight: 700;
			}
		}

		.saveBtn {
			background: #219560;
			margin-left: 260px;
			margin-top: 20px;
		}

		.cancleBtn {
			margin-left: 60px; 
			margin-top: 20px;
		}
	}
</style>
