<template>
  <div id="app" name="app">
    <keep-alive>
      <router-view></router-view>
    </keep-alive>

  </div>
</template>

<script>

import * as sysTool from "@/utils/mobile-detect";
export default {

  data() {
    return {

    };
  },
  created() {
    this.os = sysTool.GetOs();
    if(this.os == 'Android' || this.os == 'iPhone' || this.os == 'SymbianOS' || this.os == 'Windows Phone' || this.os == 'iPad' || this.os == 'iPod'){
      if (typeof WeixinJSBridge != "undefined") {
        if(this.$route.path === '/'){
          this.$router.push({
            path:"/HomeView"
          })
        }
      } else {
        this.$router.push({
          path:"/Mobile",replace:true
        })
      }
    }else {
      if(this.$route.path === '/'){
        this.$router.push({
          path:"/HomeView"
        })
      }
    }





  }
};
</script>

<style>

</style>
