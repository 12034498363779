import { render, staticRenderFns } from "./messageManagement.vue?vue&type=template&id=58af4aca&scoped=true"
import script from "./messageManagement.vue?vue&type=script&lang=js"
export * from "./messageManagement.vue?vue&type=script&lang=js"
import style0 from "./messageManagement.vue?vue&type=style&index=0&id=58af4aca&prod&lang=css"
import style1 from "./messageManagement.vue?vue&type=style&index=1&id=58af4aca&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58af4aca",
  null
  
)

export default component.exports