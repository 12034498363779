<template>
  <div class="messageManagement">
    <div class="title">
      <p class="message">消息管理</p>
      <div class="btnBox">
        <p class="remove" @click="msgRemoveApi">批量删除</p>
        <p class="allRead" @click="oneClickReadApi">全部已读</p>
      </div>
    </div>
    <div class="conTent">
      <el-table
        :data="messageList"
        align="center"
        @selection-change="handleSelectionChange"
        style="width: 100%"
      >
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column width="100" label="全选">
          <template slot-scope="scope">
            <el-badge :is-dot="scope.row.readStatus == 0" class="item">
              <img
                src="../../../assets/image/messageIocn.png"
                alt=""
                style="width: 50px; height: 50px"
              />
            </el-badge>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip width="700">
          <template slot-scope="scope">
            <div class="msgCon" @click="goMsgDetail(scope.row)">
              <div class="msgTitle">
                {{ scope.row.messageTitle }}
              </div>
              <div class="msgTime">{{ scope.row.createTime }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label=""> </el-table-column>
      </el-table>
    </div>
    <pagination
      style="margin: 0 auto"
      v-show="Total > 0"
      :total="Total"
      :page.sync="form.pageNum"
      :limit.sync="form.pageSize"
      @pagination="PageApi"
    />
  </div>
</template>

<script>
import NavCon from "@/components/navcon/navCon";
import Pagination from "@/components/Pagination/Pagination";
import { msgInfo, msgRemove, oneClickRead } from "@/request/mine";
export default {
  components: { NavCon, Pagination },
  data() {
    return {
      ids: [],
      Total: 0,
      form: {
        pageNum: 1,
        pageSize: 5,
      },
      messageList: [],
    };
  },
  created() {
    this.PageApi();
  },
  methods: {
    PageApi() {
      msgInfo(this.form).then((res) => {
        if (res.code == 200) {
          this.messageList = res.rows;
          this.Total = res.total;
        }
      });
    },
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
    },
    //一键已读
    oneClickReadApi() {
      const data = {
        readStatus: 1,
      };
      oneClickRead(data).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.PageApi();
        }
      });
    },
    //批量删除
    msgRemoveApi() {
      console.log(this.ids)
      if (this.ids.length!=0) {
        const data = {
          ids: this.ids.join(","),
        };
        msgRemove(data).then((res) => {
          if (res.code == 200) {
            this.PageApi();
            this.$message({
              message: "删除成功",
              type: "success",
            });
          }
        });
      } else {
        this.$message({
          message: "请选择你要删除的消息",
          type: "warning",
        });
      }
    },
    goMsgDetail(e) {
      this.$router.push({ name: "messageDetail", query: { id: e.id } });
    },
    goHome() {
      this.$router.push({ path: "/HomeView" });
    },
    GoIsShow() {
      this.$refs.Navigation;
    },
  },
};
</script>
<style >
.el-badge__content.is-fixed.is-dot {
  top: 5px;
}
.navpageTion {
  width: 100%;
  margin: 0 auto;
}
.el-pagination {
  display: flex;
  justify-content: center;
}
</style>
<style lang="scss" scoped>
::v-deep .btn-prev {
  padding: 0 5px !important;
}
::v-deep .btn-next {
  padding: 0 5px !important;
}
.messageManagement {
  width: 100%;
  padding: 30px 0px;
  .title {
    width: 90%;
    padding: 0 0 20px 30px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ebebeb;
    .message {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
    }
    .btnBox {
      margin-right: 30px;
      width: 140px;
      display: flex;
      .remove {
        cursor: pointer;
        margin-right: 20px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }
      .allRead {
        cursor: pointer;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #f03636;
      }
    }
  }
  .noMsg {
    width: 100%;
    height: 100%;
    p {
      text-align: center;
      color: #bbbbbb;
      line-height: 300px;
    }
  }
  .conTent {
    padding: 0 30px;
    width: 86%;

    .messageList {
      padding: 18px 0;
      border-bottom: 1px solid #ebebeb;
      display: flex;
      .imgBox {
        width: 48px;
        height: 48px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        .dot {
          width: 7px;
          height: 7px;
          position: absolute;
          top: 0;
          right: 0;
          border-radius: 50%;
          background-color: red;
        }
      }
      .msgCon {
        cursor: pointer;
        margin-left: 20px;
        .msgTitle {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #4d4d4d;
          line-height: 24px;
        }
        .msgTime {
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #b3b3b3;
          line-height: 24px;
        }
      }
    }
  }
}
</style>