<template>
	<div class="shoppingOrder" ref="whole">
		<!-- <u-toast ref="uToast" /> -->
		<div class="ReceivingAddress" @click="GoAddress">
			<img class="image-bg" src="../../assets/image/ReceivingAddress.png" />
			<div class="addressImage" v-if="Address != ''">
				<div class="ImageBox">
					<img src="../../assets/image/address.png"  />
				</div>
				<div class="addressContent">
					<div class="ContentOne">{{ Address.detailArea }}{{ Address.detailAddress }}</div>
					<div class="ContentTwo">
						<div>{{ Address.receiptPersonName }}</div>
						<div>{{ Address.receiptPhone }}</div>
					</div>
				</div>
			</div>
			<div class="addressRight" v-if="Address != ''">
        		<i class="el-icon-arrow-right"></i>
				<!-- <u-icon name="arrow-right" color="#909090" size="28"></u-icon> -->
			</div>
			<div v-if="Address == ''" style="margin-left: 30px;">请选择收货地址</div>
		</div>
		<div class="GoodsOrder">
			<div class="shoppingBox" v-for="itemOne in OrderArr" :key="itemOne.companyId">
				<div class="shoppingTitle">
					<img class="shoppingImage" src="../../assets/image/shopping.png" mode="" />
					<div class="shoppingName">{{ itemOne.companyName }}</div>
				</div>
				<div class="shoppingClass" v-for="itemTwo in itemOne.detailList" :key="itemTwo.id">
					<div class="shoppingClassImage">
						<img :src="itemTwo.skuImg" />
					</div>
					<div class="shoppingClassContent">
						<div class="ContentTitle">{{ itemTwo.goodsName }}</div>
						<div class="ContentSelect">
							<div>规格：{{ itemTwo.skuSpecs }}</div>
							<div>数量：
								<!-- <i class="el-icon-minus"></i> -->
								{{ itemTwo.goodsNum }}
								<!-- <i class="el-icon-plus"></i> -->
							</div>
						</div>
						<div class="ItemMoney">
							<p>单价：</p>
							<div>￥</div>
							<div>{{ itemTwo.skuPrice }}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="DeliveryMethod">
				<div>配送方式</div>
				<div>{{ OrderArr[0].detailList[0].setService }}</div>
			</div>
		</div>
		<!-- <div class="ClassBox">
		</div> -->
		<div class="ClassBox">
			<div class="ClassItem">
				<!-- <div class="Item" @click="PayModelShow">
					<div class="ItemTop">支付方式</div>
					<div class="ItemBottom">
						<el-select v-model="From.payWay" placeholder="请选择">
							<el-option v-for="item in payWayOptions"
								:key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</div>
				</div> -->
				<div class="Item">
					<div class="ItemTop">商品总价格</div>
					<div class="ItemBottom">
						<div class="Left">￥{{ AllMoney }}</div>
					</div>
				</div>
				<div class="Item">
					<div class="ItemTop">运费</div>
					<div class="ItemBottom">
						<div class="Left">￥{{ freightMoney }}</div>
					</div>
				</div>
				<div class="Item">
					<div class="ItemTop">订单总金额</div>
					<div class="ItemBottom">
						<div class="Left">￥{{ AllMoney + freightMoney }}</div>
					</div>
				</div>
				<div class="Item" @click="IsShowInvoice">
					<div class="ItemTop">发票</div>
					<div class="InvoiceSelect">
						<div class="Left">
							{{ invoiceFrom.invoiceType == 1 ? '电子普通发票' : invoiceFrom.invoiceType == 2 ? '增值税专用发票' : '不开发票' }}
							<span
								v-if="invoiceFrom.invoiceType != 3">-{{ invoiceFrom.invoiceHead == 1 ? '个人' : '公司' }}</span>
						</div>
						<div class="Right">
              				<i class="el-icon-arrow-right"></i>
							<!-- <u-icon name="arrow-right" color="#909090" size="28"></u-icon> -->
						</div>
					</div>
				</div>
			</div>
			<div class="ClassItem">
				<div class="Item" >
					<div class="ItemTop">备注</div>
					<div class="ItemBottom" >
						<el-input class="ItemInput" v-model="From.remark"
							placeholder="请输入您的备注信息,最多30个字"  maxlength="30" />
					</div>
				</div>
			</div>
		</div>
		<!-- <u-action-sheet :list="PayList" v-model="PayShow" @click="PayClick" border-radius="30"></u-action-sheet> -->
		<!-- 		<u-modal v-model="PaymentShow" :show-confirm-button="true" :show-cancel-button="true" title="温馨提示"
			content="您确定要支付该订单？" @confirm="GoPaymentSuccee" @cancel="NoGoPaymentShow"></u-modal> -->
		<div class="placeOrder1" v-show="fixed">
			<img class="image-bg" src="../../assets/image/billBackground.png"/>
		</div>
		<div class="placeOrder" v-show="fixed">
			<div class="OrderLeft">
				<div>支付金额：</div>
				<!-- <div>{{ From.payWay == '1' ? '支付金额：' : '订单金额: ' }}</div> -->
				<div>￥ {{ AllMoney + freightMoney }}</div>
			</div>
			<div class="Orderright" @click="GoPaymentShow">下单支付</div>
			<!-- <div class="Orderright" @click="GoPaymentShow">{{ From.payWay == '1' ? '下单支付' : '提交订单' }}</div> -->
		</div>

		<!-- 发票弹出窗 -->
		<el-dialog :show-close="true" :visible.sync="showInvoice" v-model="showInvoice" title="发票">
			<div class="showInvoice">
				<!-- <div class="titleTop">发票类型选择</div> -->
				<div class="invoiceContent" >
					<div class="titleLeft">发票类型</div>
					<div class="InvoiceList">
						<!-- <div v-for="item in InvoiceTypeList" :key="item.value" class="InvoiceActive">{{item.text}}
						</div> -->
						<div :class="invoiceFrom.invoiceType == item.value ? 'type' : 'noType'"
							v-for="item in InvoiceTypeList" :avtiveNum="invoiceFrom.invoiceType" :key="item.value"
							@click="selectInvoiceType(item)">
							<div :class="invoiceFrom.invoiceType == item.value ? 'Text' : 'noText'">{{ item.text }}
							</div>
						</div>
					</div>
					<div class="noInvoice" v-if="invoiceFrom.invoiceType == 3"></div>
					<div class="titleLeft" v-if="invoiceFrom.invoiceType != 3" style="margin-el-inputtop: 30px;">发票抬头</div>
					<div class="InvoiceList" v-if="invoiceFrom.invoiceType != 3">
						<div v-for="item in InvoiceHeaderList" :key="item.value" @click="ChangeActiveHeader(item)"
							:class="invoiceFrom.invoiceHead == item.value ? 'InvoiceActive' : 'InvoiceItem'">
							{{ item.text }}
						</div>
					</div>
					<div class="titleLeft" v-if="invoiceFrom.invoiceType != 3">发票详细信息</div>
					<div v-show="invoiceFrom.invoiceHead == 1 && invoiceFrom.invoiceType != 3" class="companyNameBox">
						<el-form :model="invoiceFrom" ref="invoiceFrom1" label-width="100px" :rules="rules">
							<el-form-item label="个人名称" :required="true" prop="personalName">
								<el-input v-model="invoiceFrom.personalName" placeholder="请输入个人名称" />
							</el-form-item>
							<!-- <el-form-item label="商品明细" prop="goodsName" :required="true"><el-input v-model="invoiceFrom.goodsName" placeholder="请输入商品明细" /></el-form-item> -->
							<el-form-item label="联系方式" :required="true" prop="contactPhone">
								<el-input v-model="invoiceFrom.contactPhone" maxlength="11" placeholder="请输入联系方式" />
							</el-form-item>
							<el-form-item label="电子邮箱" :required="true" prop="contactEmail">
								<el-input v-model="invoiceFrom.contactEmail" type="email" placeholder="请输入收票人邮箱" />
							</el-form-item>
						</el-form>
					</div>
					<div v-show="invoiceFrom.invoiceHead == 2 && invoiceFrom.invoiceType != 3" class="companyNameBox">
						<el-form :model="invoiceFrom" ref="invoiceFrom2" label-width="100px" :rules="rules">
							<el-form-item label="公司名称" :required="true" prop="companyName">
								<!-- <el-input v-model="invoiceFrom.companyName" placeholder="请输入公司名称" @change="personalNameChange"
									@click="personalNameChange" @blur="personalNameBlur" /> -->
								<el-autocomplete
									class="inline-input"
									value-key="companyName"
									v-model="invoiceFrom.companyName"
									:fetch-suggestions="personalNameChange"
									placeholder="请输入公司名称"
									@select="checkCompany"></el-autocomplete>
							</el-form-item>
							<el-form-item label="单位税号" :required="true" prop="deptTaxNo">
								<el-input v-model="invoiceFrom.deptTaxNo" placeholder="请输入单位税号" />
							</el-form-item>
							<!-- <el-form-item label="注册地址" v-if="invoiceFrom.invoiceType != 2">
								<el-input v-model="invoiceFrom.registerAddress" maxlength="50"
									placeholder="请输入注册地址,可选填" />
							</el-form-item>
							<el-form-item label="注册电话" v-if="invoiceFrom.invoiceType != 2" prop="registerPhone">
								<el-input v-model="invoiceFrom.registerPhone" maxlength="11" placeholder="请输入注册电话,可选填" />
							</el-form-item>
							<el-form-item label="开户银行" v-if="invoiceFrom.invoiceType != 2" prop="openBankName">
								<el-input v-model="invoiceFrom.openBankName" maxlength="30" placeholder="请输入开户银行,可选填" />
							</el-form-item>
							<el-form-item label="银行账户" v-if="invoiceFrom.invoiceType != 2" prop="openBankAccount">
								<el-input v-model="invoiceFrom.openBankAccount" maxlength="19"
									placeholder="请输入银行账户,可选填" />
							</el-form-item>
							<el-form-item label="注册地址" prop="registerAddress" v-if="invoiceFrom.invoiceType == 2"
								:required="true">
								<el-input v-model="invoiceFrom.registerAddress" maxlength="50" placeholder="请输入注册地址" />
							</el-form-item>
							<el-form-item label="注册电话" prop="registerPhone" v-if="invoiceFrom.invoiceType == 2"
								:required="true">
								<el-input v-model="invoiceFrom.registerPhone" maxlength="11" placeholder="请输入注册电话" />
							</el-form-item>
							<el-form-item label="开户银行" prop="openBankName" v-if="invoiceFrom.invoiceType == 2"
								:required="true">
								<el-input v-model="invoiceFrom.openBankName" maxlength="30" placeholder="请输入开户银行" />
							</el-form-item>
							<el-form-item label="银行账户" prop="openBankAccount" v-if="invoiceFrom.invoiceType == 2"
								:required="true">
								<el-input v-model="invoiceFrom.openBankAccount" maxlength="19" placeholder="请输入银行账户" />
							</el-form-item> -->
							<el-form-item label="注册地址" prop="registerAddress" :required="invoiceFrom.invoiceType == 2">
								<el-input v-model="invoiceFrom.registerAddress" maxlength="50" placeholder="请输入注册地址" />
							</el-form-item>
							<el-form-item label="注册电话" prop="registerPhone" :required="invoiceFrom.invoiceType == 2">
								<el-input v-model="invoiceFrom.registerPhone" maxlength="11" placeholder="请输入注册电话" />
							</el-form-item>
							<el-form-item label="开户银行" prop="openBankName" :required="invoiceFrom.invoiceType == 2">
								<el-input v-model="invoiceFrom.openBankName" maxlength="30" placeholder="请输入开户银行" />
							</el-form-item>
							<el-form-item label="银行账户" prop="openBankAccount" :required="invoiceFrom.invoiceType == 2">
								<el-input v-model="invoiceFrom.openBankAccount" maxlength="19" placeholder="请输入银行账户" />
							</el-form-item>
						</el-form>
						<!-- 展示开票信息 -->
						<div class="companyNameSwiper" v-if="personalNameTrue">
							<scroll-view scroll-y="true" style="height: 500px;">
								<div class="companyList" v-for="item in personalNameList" :key="item.id"
									@click="checkCompany(item)">{{ item.companyName }}</div>
							</scroll-view>
						</div>
					</div>

					<div class="invoiceButton">
						<div class="confirm" @click="invoiceButton">确定</div>
						<div class="cancle" @click="showInvoice = false">取消</div>
					</div>
				</div>
			</div>
		</el-dialog>
		<!-- <iframe v-show="showQrCode">
			<img :src="qrCode"/>
		</iframe> -->

		<!-- 地址选择窗 -->
		<el-dialog :show-close="true" :visible.sync="showAddress" v-model="showAddress" title="选择收货地址">
			<div class="mineAddress" v-if="!showAddAddress">
				<div class="title">
					收货地址
					<div class="btnBox">
						<!-- <el-button style="background: #219560; " type="primary" @click="toAddSite" >
							添加地址
						</el-button> -->
						<p class="remove" @click="toAddSite">添加地址</p>
					</div>
				</div>
				<div class="addressBox" v-for="item in addressList" :key="item.id" @click="chooseAddress(item)">
					<div class="top">
						<div class="name">{{ item.receiptPersonName }}</div>
						<div class="phone">{{ item.receiptPhone }}</div>
						<div class="tag" v-if="item.isFault==1">
							<div :class="{red:item.isFault=='1'}">默认</div>
						</div>
					</div>
					<div class="bor"></div>
					<div class="bottom">
						<div class="addres">
							<div class="city">省市区：{{item.detailArea}}</div>
							<div class="cityDetail">详细地址：{{item.detailAddress}}</div>
						</div>
					</div>
				</div>
				<pagination style="margin: 0 auto" v-show="Total > 0" :total="Total" 
					:page.sync="form.pageNum" :limit.sync="form.pageSize" @pagination="getAddressListApi" />
				<div >
					<!-- <el-button style="background: #219560; margin-left: 360px" type="primary" @click="toAddSite" >
						添加地址
					</el-button> -->
					<!-- <button @click="toAddSite">
						添加地址
						<u-icon name="plus" color="#ffffff" class="icon" :size="30"></u-icon>
					</button> -->
				</div>
			</div>
			<div class="addAddress" v-if="showAddAddress">
				<newAddress :isDialog="true" :callbackConfirm="callbackConfirm" :callbackCancle="callbackCancle"></newAddress>
			</div>
		</el-dialog>

		<!-- 支付二维码展示窗 -->
		<el-dialog :show-close="true" :visible.sync="showQrCode" v-model="showQrCode" >
			<div v-if="showQrCode" style="width:200px;height:200px;border:1px solid #eee;">
				  <vue-qr :text="qrCode" :margin="10" colorDark="#000" colorLight="#fff" :size="200"></vue-qr>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import { getAddressList	} from '@/request/mine.js';
	import { getDicts } from '@/request/dict/data';
	import { GetOs} from "@/utils/mobile-detect";
	import vueQr from 'vue-qr';
	import Pagination from "@/components/Pagination/Pagination";
	import newAddress from '../mine/address/newAddress'
	import {
		baseVisitorInvoiceAdd,
		baseVisitorInvoiceList,
		baseVisitorInvoiceUpdate,
		paymentAppPay,
		createShopOrder,
		getOrderUnionPayment,
		getOrderPayment,
		directPurchase,
		getWechatPayment
	} from '@/request/shoppingGoods.js';
	// import cloneDeep from 'lodash/cloneDeep';
	// import { throttle	} from "@/static/js/throttle.js"; //引入节流函数

	export default {
		components: { vueQr, Pagination, newAddress },
		data() {
			return {
				fixed: true,
				addressList: [],
				Total: 0,
				form: {
					pageNum: 1,
					pageSize: 5,
				},
				qrCode: '',
				showAddress: false,
				showAddAddress: false,
				addAddressForm: {
					receiptPersonName: null,
					receiptPhone: null,
					detailArea: null,
					detailAddress: null,
					isFault: null,
				},
				addAddressRules: {
					receiptPersonName: [{
							min: 2,
							required: true,
							message: '请输入收件人姓名',
							trigger: ['change', 'blur']
						},
						{
							validator: function(rule, value, callback) {
								var regex = /^[a-zA-Z\u4e00-\u9fa5]+$/;
								if (!regex.test(value)) {
									callback(new Error("请输入正确格式的姓名"));
								} else {
									callback();
								}
							},
							trigger: ['change', 'blur'],
						},
					],
					receiptPhone: [{
							required: true,
							message: '请输入联系电话',
							trigger: ['change', 'blur'],
						},
						{
							pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
							message: "请输入正确的手机号码",
							trigger: ['change', 'blur'],
						},
					],
					detailArea: [{
						required: true,
						message: '请选择所在城市',
						trigger: ['change', 'blur']
					}],
					detailAddress: [{
						required: true,
						message: '请输入您的详细地址',
						trigger: ['change', 'blur']
					}]
				},
				showQrCode: false,
				payWayOptions: [
					{
					// 	value: '1',
					// 	label: '微信支付'
					// }, {
					// 	value: '2',
					// 	label: '支付宝支付'
					// }, {
						value: '6',
						label: '银联聚合支付'
					}, {
					// 	value: '12',
					// 	label: '银联云闪付'
					// }, {
						value: '14',
						label: '对公支付'
					}, {
						value: '15',
						label: '微信支付(个人)'
					}
				],
				payerTermType: '07',	// TODO App.vue中将除电脑浏览的其他方式全部改为展示图片，所以由此处支付的均为PC  extendInfo中付款方终端类型均为电脑
				gwChnlTp: '01',
				buttonOffset: 0,
				Address: '',
				OrderArr: [],
				PaymentShow: false,
				From: {
					payWay: '14',
					remark: '',
					invoiceType: '',
					invoiceHead: '',
					personalName: '',
					contactEmail: '',
					companyName: '',
					shopCartIds: '',
					deptTaxNo: '',
					registerAddress: '',
					registerPhone: '',
					openBankName: '',
					openBankAccount: ''
				},
				personalNameTrue: false,
				invoiceFrom: {
					invoiceType: '1',
					invoiceHead: '1',
					personalName: '',
					contactEmail: '',
					goodsName: '',
					companyName: '',
					id: '',
					deptTaxNo: '',
					registerAddress: '',
					registerPhone: '',
					openBankName: '',
					openBankAccount: ''
				},
				trueInvoiceFrom: {
					invoiceType: '1',
					invoiceHead: '1',
					personalName: '',
					contactEmail: '',
					goodsName: '',
					companyName: '',
					id: '',
					deptTaxNo: '',
					registerAddress: '',
					registerPhone: '',
					openBankName: '',
					openBankAccount: ''
				},
				rules: {},
				PayShow: false,
				PayList: [{
						value: '1',
						text: '微信支付'
					},
					{
						text: '对公账户',
						value: '2'
					}
				],

				showInvoice: false,
				InvoiceTypeList: [{
						value: '1',
						text: '普通电子发票'
					},
					{
						value: '2',
						text: '增值税专用发票'
					},
					{
						value: '3',
						text: '不开发票'
					}
				],
				InvoiceHeaderList: [{
						value: '1',
						text: '个人'
					},
					{
						text: '公司',
						value: '2'
					}
				],
				AllMoney: 0,
				personalNameList: [],
				freightMoney: 0,
				InvoiceList: [],
				type: '',
				hasOrder: false
			};
		},
		created() {
			// console.log(option);

			// console.log('-------------------')
			// const userAgent = navigator.userAgent || navigator.vendor || window.opera;
			// this.$message({
			// 	message: userAgent,
			// 	type: "warning",
			// });

			// 判断支付方浏览方式，暂不需要
			// this.os = GetOs();
			// if (this.os == 'Android' || this.os == 'iPhone' || this.os == 'SymbianOS' || this.os == 'Windows Phone') {
			// 	this.payerTermType = '08'
			// 	this.gwChnlTp = '02'
			// } else if (this.os == 'iPad' || this.os == 'iPod') {
			// 	this.payerTermType = '10'
			// 	this.gwChnlTp = '02'
			// } else if (/Wearable/.test(navigator.userAgent)) {
			// 	this.payerTermType = '18'
			// } else if (this.os == 'others') {
			// 	this.payerTermType = '00'
			// } else {
			// 	this.payerTermType = '07'
			// }
			this.rules = {
				personalName: [{
						required: true,
						message: '请输入个人名称',
						trigger: ['blur', 'change']
					}, {
						validator: (rule, value, callback) => {
							const reg = /^[\u4e00-\u9fa5]*$/
							callback(reg.test(value) ? '' : new Error('个人名称格式不正确'))
						},
						trigger: ['change', 'blur']
					}
				],
				goodsName: [{
					required: true,
					message: '请输入个商品明细',
					trigger: ['blur', 'change']
				}],
				contactEmail: [{
						required: true,
						message: '请输入收票人邮箱',
						trigger: ['change', 'blur']
					},
					{
						validator: (rule, value, callback) => {
							var test =
								/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+\.)+[a-zA-Z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]{2,}))$/;
							callback(test.test(value) ? '' : new Error('单位税号格式不正确'))
						},
						message: '收票人邮箱格式不正确',
						trigger: ['change', 'blur']
					}
				],
				contactPhone: [{
					required: true,
					message: '请输入手机号',
					trigger: ['change', 'blur']
				}, {
					// 自定义验证函数，见上说明
					validator: (rule, value, callback) => {
						// 上面有说，返回true表示校验通过，返回false表示不通过
						if (/^1[3-9]\d{9}$/.test(value) ) {
							callback();
						} else {
							callback(new Error('注册电话格式不正确如为座机请加上区号'));
						}
					},
					message: '手机号码不正确',
					// 触发器可以同时用blur和change
					trigger: ['change', 'blur']
				}],
				// registerPhone: [{
				// 	required: this.invoiceFrom.invoiceType == 2,
				// 	message: '请输入手机号',
				// 	trigger: ['change', 'blur']
				// }, {
				// 	// 自定义验证函数，见上说明
				// 	validator: (rule, value, callback) => {
				// 		// 上面有说，返回true表示校验通过，返回false表示不通过
				// 		return this.$u.test.mobile(value);
				// 	},
				// 	message: '手机号码不正确',
				// 	// 触发器可以同时用blur和change
				// 	trigger: ['change', 'blur']
				// }],
				companyName: [{
					min: 2,
					max: 20,
					required: true,
					message: '请输入公司名称',
					trigger: 'blur'
				}],
				deptTaxNo: [{
						required: true,
						message: '请输入单位税号',
						trigger: ['change', 'blur']
					}, {
						validator: (rule, value, callback) => {
							var test = /^[A-Z0-9]{15}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}$/;
							callback(test.test(value) ? '' : new Error('单位税号格式不正确'))
						},
						trigger: ['change', 'blur']
					}
				],
				registerAddress: [{
						required: this.invoiceFrom.invoiceType == 2,
						message: '请输入注册地址',
						trigger: ['change', 'blur']
					}, {
						validator: (rule, value, callback) => {
							if (this.invoiceFrom.invoiceType != 2 && !value) {
								callback()
							}
							const reg = /^[\u4e00-\u9fa5]*$/
							callback(reg.test(value) ? '' : new Error('个人名称格式不正确'))
						},
						message: '注册地址格式不正确',
						trigger: ['change', 'blur']
					}
				],
				registerPhone: [{
					required: this.invoiceFrom.invoiceType == 2,
					validator: (rule, value, callback) => {
						if (this.invoiceFrom.invoiceHead == 2 && this.invoiceFrom.invoiceType == 1) {
							if (value == '') {
								callback();
							}
							if (/^1[3-9]\d{9}$/.test(value) || /^0\d{2,3}-?\d{7,8}$/.test(value) ||
								/0\d{2,3}-\d{7,8}/.test(value)) {
								callback();
							} else {
								callback(new Error('注册电话格式不正确如为座机请加上区号'));
							}
						} else {
							if (value == '') {
								callback(new Error('请输入注册电话'));
							}
							if (/^1[3-9]\d{9}$/.test(value) || /^0\d{2,3}-?\d{7,8}$/.test(value) ||
								/0\d{2,3}-\d{7,8}/.test(value)) {
								callback();
							} else {
								callback(new Error('注册电话格式不正确如为座机请加上区号'));
							}
						}
					},
					trigger: ['change', 'blur']
				}],
				openBankName: [{
					required: this.invoiceFrom.invoiceType == 2,
					validator: (rule, value, callback) => {
						console.log('********openBankName*********')
						if (this.invoiceFrom.invoiceHead == 2 && this.invoiceFrom.invoiceType == 1) {
							if (value == '') {
								callback();
							}
							if (/^[\u2E80-\u9FFF]+$/.test(value)) {
								callback();
							} else {
								callback(new Error('您输入的格式不正确'));
							}
						} else {
							if (value == '') {
								callback(new Error('请输入开户账户'));
							}
							if (/^[\u2E80-\u9FFF]+$/.test(value)) {
								callback();
							} else {
								callback(new Error('您输入的格式不正确'));
							}
						}
					},
					trigger: ['change', 'blur']
				}],
				openBankAccount: [{
					required: this.invoiceFrom.invoiceType == 2,
					validator: (rule, value, callback) => {
						if (this.invoiceFrom.invoiceHead == 2 && this.invoiceFrom.invoiceType == 1) {
							if (value == '') {
								callback();
							}
							if (value.length > 8 && value.length < 20 && /^[0-9]*$/.test(Number(value))) {
								callback();
							} else {
								callback(new Error('请输入9-19位的银行账号'));
							}
						} else {
							if (value == '') {
								callback(new Error('请输入银行账户'));
							}
							if (value.length > 8 && value.length < 20 && /^[0-9]*$/.test(Number(value))) {
								callback();
							} else {
								callback(new Error('请输入9-19位的银行账号'));
							}
						}
					},
					trigger: ['change', 'blur']
				}]
			}

			if (this.$route.query && this.$route.query.orderStr) {
				// console.log('-------------------')
				// console.log(this.$route.query.orderStr)
				this.hasOrder = this.$route.query.hasOrder
				this.OrderArr = this.$route.query.orderStr
				// this.hasOrder = cloneDeep(this.$route.query.hasOrder)
				// this.OrderArr = cloneDeep(this.$route.query.orderStr)
				// this.$route.query = {}
				this.AllMoneyFun();
			}
			// if (typeof WeixinJSBridge != "undefined") {
				this.payWayOptions.push({
					value: '3',
					label: '微信公众号支付'
				})
			// }
			this.getAddressListApi()
			this.getInvoidList()
			this.type = 1
		},
		mounted() {
			window.addEventListener('scroll', this.fixActiveBtn)
			this.buttonOffset = this.$refs.whole.offsetHeight + 430
		},
		beforeDestroy() {
			window.removeEventListener('scroll', this.fixActiveBtn)
		},
		methods: {
			// 控制右侧悬浮支付是否显示
			fixActiveBtn(e) {
				this.fixed = window.scrollY + this.$el.clientHeight < this.buttonOffset;
			},
			getInvoidList() {
				let Nameform = {
					pageSize: 999,
					pageNum: 1,
					// companyName: queryString
				};
				const scope = this
				baseVisitorInvoiceList(Nameform).then(res => {
					if (res.code === 200) {
						if (res.rows.length !== 0) {
							scope.personalNameList = res.rows;
						}
					}
				});
			},
			GoPaymentShow() {
				if (this.Address == '') {
					this.$message({
						message: "请先选择地址",
						type: "warning",
					})
					return false;
				} else {
					this.$message({
						message: "开始支付",
						type: "success",
					});
					this.GoPaymentSuccee();
				}
			},
			NoGoPaymentShow() {
				this.PaymentShow = false;
			},
			getAddressListApi() {
				const scope = this
				getAddressList({ pageNum: 1, pageSize: 200, }).then(res => {
					scope.addressList = res.rows
					scope.Total = res.total
					res.rows.forEach(item => {
						if (item.isFault === 1) {
							this.Address = item;
						}
					});
				});
			},
			toAddSite() {
				this.showAddAddress = true
			},
			chooseAddress(address) {
				this.Address = address
				this.showAddress = false
			},
			callbackConfirm() {
				this.showAddAddress = false
				this.getAddressListApi();
			},
			callbackCancle() {
				this.showAddAddress = false
			},
			selectInvoiceType(e) {
				// console.log(this.InvoiceHeaderList)
				if (e.value == 2 && this.InvoiceHeaderList.length == 2) {
					this.invoiceFrom.invoiceHead = 2;
					this.InvoiceHeaderList.shift();
				} else if (e.value == 1 && this.InvoiceHeaderList.length == 1) {
					this.invoiceFrom.invoiceHead = 1;
					this.InvoiceHeaderList.unshift({
						value: '1',
						text: '个人'
					});
				}
				this.invoiceFrom.invoiceType = e.value
			},
			AllMoneyFun() {
				this.freightMoney = 0;
				this.AllMoney = 0;
				if (this.OrderArr && this.OrderArr.forEach) {
				this.OrderArr.forEach(itemOne => {
					itemOne.detailList.forEach(itemTwo => {
					this.freightMoney += itemTwo.goodsFreight;
					this.AllMoney += itemTwo.skuPrice * itemTwo.goodsNum;
					});
				});
				}
			},
			GoAddress() {
				// TODO 选地址弹窗
				this.showAddress = true
				// this.$router.push({path: '/mineAddress', query: {}});

			},
			PayModelShow() {
				this.PayShow = true;
			},
			PayClick(index) {
				this.From.payWay = this.PayList[index].value;
			},

			ChangeActiveHeader(e) {
				this.invoiceFrom.invoiceHead = e.value;
				this.invoiceFrom.personalName = '';
				this.invoiceFrom.contactEmail = '';
				this.invoiceFrom.companyName = '';
				this.invoiceFrom.deptTaxNo = '';
				this.invoiceFrom.registerAddress = '';
				this.invoiceFrom.registerPhone = '';
				this.invoiceFrom.openBankName = '';
				this.invoiceFrom.id = '';
				this.invoiceFrom.openBankAccount = '';
			},
			IsShowInvoice() {
				this.showInvoice = true;
				this.invoiceFrom = this.trueInvoiceFrom
			},
			personalNameChange(queryString, cb) {
				// let Nameform = {
				// 	pageSize: 999,
				// 	pageNum: 1,
				// 	companyName: queryString
				// };
				const scope = this
				this.personalNameList
				let personalNameList = this.personalNameList
        		let results = queryString ? personalNameList.filter(this.createFilter(queryString)) : personalNameList
				cb(results)
				// baseVisitorInvoiceList(Nameform).then(res => {
				// 	if (res.code === 200) {
				// 		if (res.rows.length !== 0) {
				// 			// scope.personalNameTrue = true;
				// 			// scope.personalNameList = res.rows;
				// 			cb(res.rows)
				// 			return
				// 		}
				// 	}
				// 	cb()
				// });
			},
			createFilter(queryString) {
				return (invoice) => {
					return (invoice.companyName.indexOf(queryString) > 0);
				};
			},
			personalNameBlur() {
				this.personalNameTrue = false;
			},
			checkCompany(e) {
				console.log('*******checkCompany*********')
				console.log(e)
				this.invoiceFrom.invoiceType = e.invoiceType.toString();
				this.invoiceFrom.invoiceHead = e.invoiceHead.toString();
				this.From.invoiceType = e.invoiceType.toString();
				this.From.invoiceHead = e.invoiceHead.toString();
				this.invoiceFrom.contactPhone = e.contactPhone;
				this.invoiceFrom.personalName = e.personalName;
				this.invoiceFrom.contactEmail = e.contactEmail;
				this.invoiceFrom.companyName = e.companyName;
				this.invoiceFrom.deptTaxNo = e.deptTaxNo;
				this.invoiceFrom.registerAddress = e.registerAddress;
				this.invoiceFrom.registerPhone = e.registerPhone;
				this.invoiceFrom.openBankName = e.openBankName;
				this.invoiceFrom.id = e.id;
				this.invoiceFrom.openBankAccount = e.openBankAccount;
				this.personalNameTrue = false;
			},
			invoiceButton() {
				if (this.invoiceFrom.invoiceType != 3) {
					if (this.invoiceFrom.invoiceHead == 1) {
						this.$refs.invoiceFrom1.validate(valid => {
							if (valid) {
								this.showInvoice = false
								this.trueInvoiceFrom = this.invoiceFrom
							}
						});
					} else if (this.invoiceFrom.invoiceHead == 2) {
						this.$refs.invoiceFrom2.validate(valid => {
							if (valid) {
								if (this.invoiceFrom.id === '') {
									baseVisitorInvoiceAdd(this.invoiceFrom).then(res => {
										if (res.code === 200) {
											this.showInvoice = false
											this.trueInvoiceFrom = this.invoiceFrom
										}
									});
								} else {
									baseVisitorInvoiceUpdate(this.invoiceFrom).then(res => {
										if (res.code === 200) {
											this.showInvoice = false
											this.trueInvoiceFrom = this.invoiceFrom
										}
									});
								}
							}
						});
					}
				} else {
					this.showInvoice = false;
					this.trueInvoiceFrom = this.invoiceFrom
				}
			},
			// 公对公支付跳转
			B2BForm(msg) {
				let div = document.createElement('div');
				let form = document.createElement('form');
				form.id = 'unityPayForm'
				form.method = 'POST'
				// form.action = 'https://wxpay.cmbc.com.cn/mobilePlatform/unitypay/unityPcPayReq.do'
				// form.action = 'https://wxpay.cmbc.com.cn/mobilePlatform/unitypay/unityPayReq.do'
				form.action = 'https://epay.cmbc.com.cn/appweb/unitypay/unityPayReq.do'
				// form.mode = 'cors'
				
				var textarea = document.createElement('textarea');
				textarea.name = 'context'
				textarea.id = 'context'
				textarea.value = msg
				// textarea.text = msg
				// textarea.setAttribute()
				form.appendChild(textarea)

				// let form1 = $('<form id="unityPayForm" method="POST" action="https://wxpay.cmbc.com.cn/mobilePlatform/unitypay/unityPcPayReq.do">'
				// 	+ '<textarea name="context" id="context">' + msg + '</textarea></form>')

				// console.log('******2******')
				// console.log(textarea)
				// console.log(form)
				// console.log(textarea.value)
				div.appendChild(form)
				document.body.appendChild(div)
				// form.addEventListener('submit', function(event) {
				// 	console.log('**********3***********')
				// 	event.preventDefault(); // 阻止表单默认提交
				
				// 	var formData = new FormData(form);
				
				// 	fetch('https://wxpay.cmbc.com.cn/mobilePlatform/unitypay/unityPayReq.do', {
				// 		method: 'POST',
				// 		body: formData,
				// 		mode: 'cors' // 指定跨域模式
				// 	})
				// 	.then(response => response.json())
				// 	.then(data => console.log(data))
				// 	.catch(error => console.error(error));
				// });
				form.submit()
			},
			GoPaymentSuccee() {
				try {
					// console.log(this.OrderArr)
					this.OrderArr.forEach(itemOne => {
						itemOne.detailList.forEach(itemTwo => {
							if (itemTwo.isExpire == 1) {
								throw new Error('end');
							}
						});
					});
				} catch (err) {
					this.$message({
						message: "当前商品已失效，不可购买",
						type: "warning",
					});
					return false;
				}
				// console.log('=========GoPaymentSuccee=========')
				// console.log(this.type)
				if (this.type == 2) {
					let shopCartIds = '';
					this.OrderArr.forEach(item => {
						item.detailList.forEach(item2 => {
							shopCartIds += item2.shopCartId + ',';
						});
					});
					shopCartIds = shopCartIds.substring(0, shopCartIds.lastIndexOf(','));
					if (this.trueInvoiceFrom.companyName !== '' || this.trueInvoiceFrom.personalName !== '') {
						this.From.personalName = this.trueInvoiceFrom.personalName;
						this.From.contactPhone = this.trueInvoiceFrom.contactPhone;
						this.From.companyName = this.trueInvoiceFrom.companyName;
						this.From.contactEmail = this.trueInvoiceFrom.contactEmail;
						this.From.invoiceType = this.trueInvoiceFrom.invoiceType;
						this.From.invoiceHead = this.trueInvoiceFrom.invoiceHead;
						this.From.deptTaxNo = this.trueInvoiceFrom.deptTaxNo;
						this.From.registerAddress = this.trueInvoiceFrom.registerAddress;
						this.From.registerPhone = this.trueInvoiceFrom.registerPhone;
						this.From.openBankName = this.trueInvoiceFrom.openBankName;
						this.From.openBankAccount = this.trueInvoiceFrom.openBankAccount;
					}
					this.From.receiptAddressId = this.Address.id;
					this.From.shopCartIds = shopCartIds;
					let _this = this;
					createShopOrder(_this.From).then(res => {
						// console.log(res)
						if (res.data.result == 'failed') {
							_this.$refs.uToast.show({
								title: res.msg
							});
						}
						if (res.data.result == 'success') {
							if (this.OrderArr.length === 1) {
								let OrderFrom = {
									orderId: res.data.orderIds,
									orderType: 2,
									openid: uni.getStorageSync('openId')
								};
								if (this.From.payWay == '1') {
									paymentAppPay(OrderFrom).then(res2 => {
										if (res2.code == 0) {
											uni.requestPayment({
												provider: 'wxpay',
												timeStamp: res2.data.timeStamp,
												nonceStr: res2.data.nonceStr,
												package: res2.data.packages,
												signType: 'RSA',
												paySign: res2.data.paySign,
												success: res3 => {
													this.$refs.uToast.show({
														title: '支付成功'
													});
													let dataTime;
													let yy = new Date().getFullYear();
													let mm = new Date().getMonth() + 1;
													let dd = new Date().getDate();
													let hh = new Date().getHours();
													let mf = new Date().getMinutes() < 10 ?
														'0' + new Date().getMinutes() :
														new Date().getMinutes();
													let ss = new Date().getSeconds() < 10 ?
														'0' + new Date().getSeconds() :
														new Date().getSeconds();
													dataTime =
														`${yy}-${mm}-${dd} ${hh}:${mf}:${ss}`;
													let PaymentFrom = {
														payWay: this.From.payWay,
														Money: this.AllMoney + this
															.freightMoney,
														mytime: dataTime,
														userName: uni.getStorageSync(
															'nickName')
													};
													uni.navigateTo({
														url: '/pagesC/PaymentSuccee/PaymentSuccee?PaymentFrom=' +
															encodeURIComponent(JSON
																.stringify(PaymentFrom)
																)
													});
												},
												fail: err => {}
											});
										}
									});
								} else {
									let dataTime;
									let yy = new Date().getFullYear();
									let mm = new Date().getMonth() + 1;
									let dd = new Date().getDate();
									let hh = new Date().getHours();
									let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() :
										new Date().getMinutes();
									let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() :
										new Date().getSeconds();
									dataTime = `${yy}-${mm}-${dd} ${hh}:${mf}:${ss}`;
									let PaymentFrom = {
										payWay: this.From.payWay,
										Money: this.AllMoney + this.freightMoney,
										mytime: dataTime,
										userName: uni.getStorageSync('nickName')
									};
									uni.redirectTo({
										url: '/pagesC/PaymentSuccee/PaymentSuccee?PaymentFrom=' +
											encodeURIComponent(JSON.stringify(PaymentFrom))
									});
								}
							} else {
								let OrderFrom = {
									orderId: res.data.orderIds,
									mainOrderNo: res.data.mainOrderNo,
									orderType: 2,
									openid: uni.getStorageSync('openId')
								};
								if (this.From.payWay == '1') {
									paymentAppPay(OrderFrom).then(res2 => {
										if (res2.code == 0) {
											uni.requestPayment({
												provider: 'wxpay',
												timeStamp: res2.data.timeStamp,
												nonceStr: res2.data.nonceStr,
												package: res2.data.packages,
												signType: 'RSA',
												paySign: res2.data.paySign,
												success: res3 => {
													this.$refs.uToast.show({
														title: '支付成功'
													});
													let dataTime;
													let yy = new Date().getFullYear();
													let mm = new Date().getMonth() + 1;
													let dd = new Date().getDate();
													let hh = new Date().getHours();
													let mf = new Date().getMinutes() < 10 ?
														'0' + new Date().getMinutes() :
														new Date().getMinutes();
													let ss = new Date().getSeconds() < 10 ?
														'0' + new Date().getSeconds() :
														new Date().getSeconds();
													dataTime =
														`${yy}-${mm}-${dd} ${hh}:${mf}:${ss}`;
													let PaymentFrom = {
														payWay: this.From.payWay,
														Money: this.AllMoney + this
															.freightMoney,
														mytime: dataTime,
														userName: uni.getStorageSync(
															'nickName')
													};
													uni.navigateTo({
														url: '/pagesC/PaymentSuccee/PaymentSuccee?PaymentFrom=' +
															encodeURIComponent(JSON
																.stringify(PaymentFrom)
																)
													});
												},
												fail: err => {}
											});
										}
									});
								} else {
									let dataTime;
									let yy = new Date().getFullYear();
									let mm = new Date().getMonth() + 1;
									let dd = new Date().getDate();
									let hh = new Date().getHours();
									let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() :
										new Date().getMinutes();
									let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() :
										new Date().getSeconds();
									dataTime = `${yy}-${mm}-${dd} ${hh}:${mf}:${ss}`;
									let PaymentFrom = {
										payWay: this.From.payWay,
										Money: this.AllMoney + this.freightMoney,
										mytime: dataTime,
										userName: uni.getStorageSync('nickName')
									};
									uni.redirectTo({
										url: '/pagesC/PaymentSuccee/PaymentSuccee?PaymentFrom=' +
											encodeURIComponent(JSON.stringify(PaymentFrom))
									});
								}
							}
						}
					});
				} else {
					const scope = this
					// 已有订单，直接支付
					if (scope.hasOrder) {
						let OrderFrom = {
							tradeType: scope.From.payWay,
							orderId: scope.OrderArr[0].orderId,
							orderType: 2,
							payerTermType: scope.payerTermType,
							gwChnlTp: scope.gwChnlTp,
						};
						scope.toPay(OrderFrom)
					}

					let From = {};
					if (this.trueInvoiceFrom.companyName !== '' || this.trueInvoiceFrom.personalName !== '') {
						From.personalName = this.trueInvoiceFrom.personalName;
						From.companyName = this.trueInvoiceFrom.companyName;
						From.contactPhone = this.trueInvoiceFrom.contactPhone;
						From.contactEmail = this.trueInvoiceFrom.contactEmail;
						From.invoiceType = this.trueInvoiceFrom.invoiceType;
						From.invoiceHead = this.trueInvoiceFrom.invoiceHead;
						From.deptTaxNo = this.trueInvoiceFrom.deptTaxNo;
						From.registerAddress = this.trueInvoiceFrom.registerAddress;
						From.registerPhone = this.trueInvoiceFrom.registerPhone;
						From.openBankName = this.trueInvoiceFrom.openBankName;
						From.openBankAccount = this.trueInvoiceFrom.openBankAccount;
					}
					From.receiptAddressId = this.Address.id;
					From.payWay = this.From.payWay;
					From.remark = this.From.remark;
					From.goodsNum = this.OrderArr[0].detailList[0].goodsNum;
					From.skuId = this.OrderArr[0].detailList[0].skuId;
					// console.log(From)
					directPurchase(From).then(res => {
						if (res.data.result == 'failed') {
							this.$message({
								message: res.msg,
								type: "warning",
							})
						}
						if (res.data.result == 'success') {
							let OrderFrom = {
								tradeType: scope.From.payWay,
								orderId: res.data.orderIds,
								orderType: 2,
								payerTermType: scope.payerTermType,
								gwChnlTp: scope.gwChnlTp,
								// openid: uni.getStorageSync('openId')
							};
							scope.toPay(OrderFrom)
						}
					});
				}
			},
			toPay(OrderFrom) {
				const scope = this
				// 跳转银联支付界面
				if (scope.From.payWay == '14') {
					getOrderUnionPayment(OrderFrom).then(res2 => {
						if (res2.code == 0) {
							// 跳转到银行支付页面
							if (res2.data && res2.data.data) {
								// console.log('******1******')
								scope.B2BForm(res2.data.data)
							} else {
								scope.$message({
									message: "发起支付失败",
									type: "warning",
								})
							}
							// uni.requestPayment({
							// 	provider: 'wxpay',
							// 	timeStamp: res2.data.timeStamp,
							// 	nonceStr: res2.data.nonceStr,
							// 	package: res2.data.packages,
							// 	signType: 'RSA',
							// 	paySign: res2.data.paySign,
							// 	success: res3 => {
							// 		this.$refs.uToast.show({
							// 			title: '支付成功'
							// 		});
							// 		let dataTime;
							// 		let yy = new Date().getFullYear();
							// 		let mm = new Date().getMonth() + 1;
							// 		let dd = new Date().getDate();
							// 		let hh = new Date().getHours();
							// 		let mf = new Date().getMinutes() < 10 ? '0' +
							// 			new Date().getMinutes() : new Date()
							// 			.getMinutes();
							// 		let ss = new Date().getSeconds() < 10 ? '0' +
							// 			new Date().getSeconds() : new Date()
							// 			.getSeconds();
							// 		dataTime =
							// 			`${yy}-${mm}-${dd} ${hh}:${mf}:${ss}`;
							// 		let PaymentFrom = {
							// 			payWay: this.From.payWay,
							// 			Money: this.AllMoney + this
							// 				.freightMoney,
							// 			mytime: dataTime,
							// 			userName: uni.getStorageSync(
							// 				'nickName')
							// 		};
							// 		uni.navigateTo({
							// 			url: '/pagesC/PaymentSuccee/PaymentSuccee?PaymentFrom=' +
							// 				encodeURIComponent(JSON
							// 					.stringify(PaymentFrom))
							// 		});
							// 	},
							// 	fail: err => {}
							// });
						} else {
							scope.$message({
								message: res2.msg,
								type: "warning",
							})
						}
					}).catch(() => {});
				} else if (scope.From.payWay == '15') {
					getWechatPayment(OrderFrom).then(res2 => {
						if (res2.code == 0) {
							// 跳转到银行支付页面
							if (res2.data && res2.data.data) {
								console.log('******1******')
								console.log(res2.data.data)
								const resData = JSON.parse(res2.data.data)
								console.log(resData.code_url)
								scope.qrCode = resData.code_url
								scope.showQrCode = true
							} else {
								scope.$message({
									message: "发起支付失败",
									type: "warning",
								})
							}
						} else {
							scope.$message({
								message: res2.msg,
								type: "warning",
							})
						}
					}).catch(() => {});
				} else {
					// 生成支付二维码
					getOrderPayment(OrderFrom).then(res2 => {
						if (res2.code == 0) {
							if (res2.data && res2.data.data) {
								// 展示支付二维码
								// console.log('******1******')
								// console.log(res2.data)
								if (scope.From.payWay == '3') {

									WeixinJSBridge.invoke('getBrandWCPayRequest', {
										"appId": "wx2b0ad640ef47938b",
										"timeStamp": "1487849566132",
										"nonceStr": "bg2i7ft66r9cz5tqjplb9fnx0en45ijk",
										"package": "prepay_id=wx2017022319324563eba808040444884119",
										"signType": "MD5",
										"paySign": "B06B8BD02C82C495D3FB52BEC5489F7E"
									}, function(res) {
										alert(res.err_msg);
									});

								} else if (scope.From.payWay == '6') {
									// scope.qrCode = res2.data.data	// TODO 正式环境用此
									let tmpCode = encodeURIComponent(res2.data.data)
									scope.qrCode = 'https://wxpay.cmbc.com.cn/mobilePlatform/union/cmbcRedirect.do?qrCode=' + tmpCode
									scope.showQrCode = true
									// console.log('******123*****')
								}
							} else {
								scope.$message({
									message: "发起支付失败",
									type: "warning",
								})
							}
						} else {
							scope.$message({
								message: res2.msg,
								type: "warning",
							})
						}
					}).catch(() => {});
				}
			}
		}
	};
</script>

<style lang="scss">
	page {
		background-color: #f7f7f7;
	}

	.shoppingOrder {
		.ReceivingAddress {
			position: relative;
			width: 60%;
			height: 80px;
			// margin-top: 30px;
      		margin: 30px auto 0;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.image-bg {
				position: absolute;
				z-index: 3;
				right: 0;
				top: 0;
				width: 100%;
				height: 100%;
			}

			.addressImage {
				display: flex;
			  	z-index: 5;
				align-items: center;

				.ImageBox {
					max-width: 60px;
					max-height: 30px;
					margin: 0px 15px 10px 140px;

					img {
						width: 15px;
						// max-height: 60px;
					}
				}

				.addressContent {
					width: 100%;
          			margin-left: 30px;
					cursor: pointer;

					.ContentOne {
						font-size: 16px;
						font-weight: bold;
						color: #3d3d3d;
						display: -webkit-box;
						overflow: hidden;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
					}

					.ContentTwo {
						display: flex;
						font-size: 14px;
						font-weight: normal;
						color: #3d3d3d;
						margin-top: 5px;

						div {
							margin-left: 30px;
						}
					}
				}
			}

			.addressRight {
			  	z-index: 5;
				width: 50px;
				height: 100%;
				margin-right: 180px;
				cursor: pointer;

				i {
					max-width: 25px;
					max-height: 25px;
          			margin-top: 30px;
				}
			}
		}

		.GoodsOrder {
			width: 60%;
			// border-radius: 10px;
			background-color: #ffffff;
			margin: 0 auto 0;
			z-index: 5;

			.shoppingBox {
				width: 690px;
				background-color: #ffffff;
				margin: 0 auto;
				border-radius: 10px;
				border-bottom: 1px solid #ebebeb;

				.shoppingTitle {
					height: 60px;
					display: flex;
					align-items: center;

					.shoppingImage {
						max-width: 28px;
						max-height: 28px;
						margin: 0 20px;
					}

					.shoppingName {
						color: #3d3d3d;
						font-size: 14px;
					}
				}

				.shoppingClass {
					margin-left: 40px;
					padding: 0 0 30px 0;
					display: flex;

					.shoppingClassImage {
						// width: 200px;
						height: 200px;
						border-radius: 6px;
						margin: 0 20px;

						img {
							// max-width: 200px;
							max-height: 200px;
							border-radius: 6px;
						}
					}

					.shoppingClassContent {
						margin-top: 20px;
						margin-left: 30px;

						.ContentTitle {
							width: 454px;
							font-size: 14px;
							font-weight: 600;
							color: #333333;
							display: -webkit-box;
							overflow: hidden;
							-webkit-line-clamp: 1;
							-webkit-box-orient: vertical;
						}

						.ContentSelect {
							// display: flex;
							align-items: center;
							margin-top: 15px;

							div {
								width: 400px;
								font-size: 14px;
								color: #999999;
								display: -webkit-box;
								overflow: hidden;
								line-height: 40px;
								-webkit-line-clamp: 1;
								-webkit-box-orient: vertical;
							}

							i {
								width: 10px;
								margin-right: 10px;
								margin-left: 10px;
							}
						}

						.ItemMoney {
							display: flex;
							font-size: 14px;
							color: #f66a1d;
							line-height: 40px;

							p {
								font-size: 14px;
								color: #999999;
								display: -webkit-box;
								overflow: hidden;
								-webkit-line-clamp: 1;
								-webkit-box-orient: vertical;
							}

							div:nth-child(2) {
								font-weight: bold;
								font-size: 14px;
							}
						}
					}
				}
			}

			.DeliveryMethod {
				height: 60px;
        		margin-left: 120px;
				font-size: 14px;
				font-weight: normal;
				color: #3d3d3d;
				display: flex;
				align-items: center;
				// justify-content: space-between;

				div:nth-child(1) {
					// margin-left: 15px;
				}

				div:nth-child(2) {
					color: #f66a1d;
					margin-left: 130px;
				}
			}
		}

		.ClassBox {
			width: 60%;
			background: #ffffff;
			// border-radius: 10px;
			margin: 0 auto 30px;
			z-index: 5;

			.ClassItem {
				width: 100%;
				margin-left: 120px;
				// margin: 0 auto;

				.Item {
					height: 60px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					border-bottom: 1px solid #ebebeb;
					border: 0;

					.ItemTop {
						font-size: 14px;
						font-weight: 400;
						color: #3d3d3d;
					}

					.ItemBottom {
						width: 80%;
						display: flex;
						// justify-content: space-between;

						.Left {
							font-size: 14px;
							color: #909090;
						}

						.Right {}

						.ItemInput {
							width: 50%;
						}
					}

					.InvoiceSelect{
						width: 80%;
						display: flex;
						cursor: pointer;

						.Left {
							font-size: 14px;
							color: #909090;
						}

						.Right {}

						.ItemInput {
							width: 50%;
						}
					}
				}
			}
		}

		.showInvoice {
			width: 80%;
			margin: 0 auto;
			z-index: 8;

			.titleTop {
				font-size: 14px;
				line-height: 30px;
				font-weight: bold;
				color: #3d3d3d;
				height: 30px;
				background-color: #ffffff;
				z-index: 8;
				text-align: center;
				position: fixed;
				// top: 0;
				// width: 750px;
				// z-index: 9999;
			}

			.invoiceContent {
				// width: 65%;
				margin: 0px 0px 0px 0px;

				.titleLeft {
					font-size: 14px;
					font-weight: bold;
					color: #3d3d3d;
				}
	
				.InvoiceList {
					display: flex;
					align-items: center;
					margin-top: 10px;
					margin-left: 60px;
					margin-bottom: 30px;
	
					.type {
						margin-right: 20px;
						width: 140px;
						height: 40px;
						border: 1px solid #f66a1d;
						border-radius: 50px;
	
						.Text {
							font-size: 14px;
							text-align: center;
							line-height: 40px;
							color: #f66a1d;
						}
					}
	
					.noType {
						margin-right: 20px;
						width: 140px;
						height: 40px;
						border: 1px solid #ebebeb;
						border-radius: 50px;
	
						.noText {
							font-size: 14px;
							text-align: center;
							line-height: 40px;
							color: #3d3d3d;
						}
					}
	
					.InvoiceItem {
						height: 40px;
						padding: 0 50px;
						line-height: 40px;
						border-radius: 100px;
						margin-right: 30px;
						box-sizing: border-box;
						border: 1px solid #ebebeb;
						font-size: 14px;
						color: #3d3d3d;
					}
	
					.InvoiceActive {
						height: 40px;
						padding: 0 50px;
						line-height: 40px;
						border-radius: 100px;
						margin-right: 30px;
						box-sizing: border-box;
						border: 1px solid #f66a1d;
						font-size: 14px;
						color: #f66a1d;
					}
				}
	
				.companyNameBox {
					position: relative;
					margin-top: 30px;
					margin-left: 30px;
	
					.companyNameSwiper {
						height: 500px;
						width: 690px;
						border-radius: 15px;
						background-color: #ffffff;
						box-shadow: 0 0 15px 15px #ebebeb;
						position: absolute;
						left: 0px;
						z-index: 9999;
						top: 121px;
	
						.companyList {
							line-height: 100px;
							text-indent: 30px;
							border-bottom: 1px solid #ebebeb;
						}
					}

					el-input {
						width: 20px;
					}
				}
	
				.invoiceButton {
					// height: 60px;
					margin-top: 20px;
					width: 80%;
					text-align: center;
					color: #ffffff;
					display: flex;
					// position: fixed;
					z-index: 9999;

					.confirm {
						height: 40px;
						margin-left: 100px;
						border-radius: 100px;
						background: linear-gradient(270deg, #f46720 0%, #f1491a 100%);
						box-shadow: 0px 10px 16px 0px rgba(244, 99, 31, 0.2);
						width: 120px;
						text-align: center;
						line-height: 40px;
						color: #ffffff;
						// position: fixed;
						// bottom: 30px;
						z-index: 9999;
					}

					.cancle {
						height: 40px;
						margin-left: 100px;
						border-radius: 100px;
						background: linear-gradient(270deg, #c0c0c0 0%, #dedede 100%);
						box-shadow: 0px 10px 16px 0px rgba(129, 129, 129, 0.2);
						width: 120px;
						text-align: center;
						line-height: 40px;
						color: #ffffff;
					}
				}
			}

		}

		.placeOrder1 {
			width: 100%;
			height: 120px;
			// background-color: #ffffff;
			display: flex;
			position: fixed;
			bottom: 130px;
			flex-direction: column;
			justify-content: flex-end;
			align-items:flex-end;
			z-index: 1;
			pointer-events: none;

			.image-bg {
				bottom: 120px;
				margin-right: 80px;
				width: 140px;
				height: 220px;
			}
		}

		.placeOrder {
			width: 100%;
			height: 120px;
			// background-color: #ffffff;
			display: flex;
			position: fixed;
			bottom: 160px;
			flex-direction: column;
			justify-content: flex-end;
			align-items:flex-end;
			// bottom: 0;
			// display: flex;
			// align-items: center;
			// justify-content: space-between;
			z-index: 2;
			pointer-events: none;

			.OrderLeft {
				margin-right: 120px;
				margin-bottom: 10px;
				pointer-events: auto;

				div:nth-child(1) {
					font-size: 14px;
					font-weight: normal;
					color: #3d3d3d;
					margin-bottom: 6px;
				}

				div:nth-child(2) {
					font-size: 14px;
					font-weight: normal;
					color: #f66a1d;
					font-weight: 700;
				}
			}

			.Orderright {
				z-index: 2;
				margin-right: 112px;
				width: 80px;
				height: 30px;
				border-radius: 100px;
				background: #f66a1d;
				font-size: 14px;
				color: #ffffff;
				text-align: center;
				line-height: 30px;
				pointer-events: auto;
				cursor: pointer;
			}
		}

		.mineAddress {
			width: 80%;
			// height: 400px;
			// background-color: #f7f7f7;
			// padding: 30px 0px;
			// display: flex;
			// justify-content: center;

			.title {
				width: 86%;
				padding: 0 0 20px 30px;
				margin-left: 100px;
				font-size: 16px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #333333;
				border-bottom: 1px solid #ebebeb;
				display: flex;
				justify-content: space-between;

				.btnBox {
					// margin-right: 30px;
					width: 140px;
					display: flex;
					.remove {
						cursor: pointer;
						margin-top: 5px;
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						// color: #999999;
						color: dodgerblue;
					}
				}
			}

			.addressBox {
				background-color: #fff;
				padding: 15px 15px;
				margin-bottom: 10px;
				margin-left: 100px;
				width: 70%;

				.top {
					display: flex;
					font-weight: bold;
					align-items: center;
					font-size: 14px;
					margin-bottom: 10px;
					margin-left: 40px;

					.phone {
						margin-left: 120px;
					}

					.tag {
						display: flex;
						margin-left: 30px;

						div {
							align-self: flex-end;
							display: block;
							width: 40px;
							height: 25px;
							line-height: 25px;
							color: #ffffff;
							font-size: 12px;
							border-radius: 6px;
							text-align: center;
							background-color: rgb(49, 145, 253);
						}

						.red {
							background-color: rgb(251, 95, 95)
						}
					}
				}

				.bor {
					width: 100%;
					height: 1px;
					background-color: #ebebeb;
				}

				.bottom {
					display: flex;
					margin-top: 10px;
					margin-left: 40px;
					font-size: 14px;
					justify-content: space-between;
					align-items: center;
					color: #999999;
					.addres{
						display: flex;
						flex-direction: column;
						width: 500px;
					}
					.edit{
						// flex-direction: row;
						// width: 200px;
						display: flex;
						margin-left: 10px;
						
						.editBtn{
							color:dodgerblue;
							cursor: pointer;
						}
						.delBtn{
							color: crimson;
							margin-left: 20px;
							cursor: pointer;
						}
					}

					.remove{
						margin-left: 10px;
						color:dodgerblue;
						cursor: pointer;
					}
				}


			}

			.addSite {
				display: flex;
				justify-content: space-around;
				width: 92%;
				height: 88px;
				background-color: #F4631F;
				margin: 0 auto;
				line-height: 100px;
				position: absolute;
				bottom: 20px;
				border-radius: 60px;
				font-size: 14px;

				.add {
					display: flex;
					align-items: center;
					color: #ffffff;

					.icon {
						margin-right: 10px;
					}
				}
			}
		}

		.addAddress {

			.newAddress{
				width: 80%;
				height: 400px;
				// background-color: #f7f7f7;
				// padding: 20px 26px;

				.form {
					margin-left: 10px;
					padding: 0 20px;
					border-radius: 20px;
					// background-color: #fff;
	
					.newAddressTitle {
						height: 70px;
						line-height: 70px;
						font-size: 30px;
						color: #3D3D3D;
						font-weight: 700;
					}
				}
	
				.saveBtn {
					background: #219560;
					margin-left: 260px;
					margin-top: 20px;
				}
	
				.cancleBtn {
					margin-left: 60px; 
					margin-top: 20px;
				}
			}
		}

	}
</style>
