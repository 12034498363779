<template>
  <div class="costEngineerDetails">
    <div class="route">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="myrou" :to="{ path: '/HomeView' }"
          >首页</el-breadcrumb-item
        >
        <el-breadcrumb-item class="myrou" :to="{ name: 'costEngineer',query:{id:3} }"
          >找造价</el-breadcrumb-item
        >
        <el-breadcrumb-item>造价师详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="costEng">
      <div class="costEng_info">
        <div class="costEng_info_con">
          <div class="costEng_img">
            <div class="img">
              <img :src="costEngineList.personalImg" alt="" />
            </div>
          </div>
          <div class="info">
            <div class="info_title">
              <div class="name">
                <p>{{ costEngineList.personalName }}</p>
                <div style="display: flex">
                  <el-rate
                    v-model="value"
                    disabled
                    :colors="[
                      '#F6461D',
                      '#F6461D',
                      '#F6461D',
                      '#F6461D',
                      '#F6461D',
                    ]"
                    text-color="#F6461D"
                    score-template="{value}"
                  >
                  </el-rate>
                </div>
              </div>

              <div class="share">
                <div class="shoucang" @click="follow(costEngineList)">
                  <!-- <i class="iconfont icon-wujiaoxing-"></i> -->
                  <p>{{ costEngineList.isCollect == 0 ? "收藏" : "已收藏" }}</p>
                </div>
                <!-- <div class="fenxiang">
                  <i class="el-icon-share"></i>
                  <p>分享</p>
                </div> -->
              </div>
            </div>
            <div class="info_case">
              <div class="case_f">
                <p>造价案例</p>
                <p>{{ costEngineList.caseNums }}</p>
              </div>
              <div class="case_t">
                <p>工作经验</p>
                <p>
                  {{ costEngineList.year + year }}
                </p>
              </div>
              <div class="case_th">
                <p>服务区域</p>
                <p>{{ costEngineList.serviceArea }}</p>
              </div>
              <div class="info_certificate">
                <div class="certificate">
                  <p>获得证书</p>
                  <p>一级造价工程师</p>
                </div>
              </div>
            </div>
            <div class="info_introduce">
              <div class="introduce">
                <p>个人介绍：</p>
                <p>
                  {{ costEngineList.personalIntroduction }}
                </p>
              </div>
            </div>
            <div class="inquiry" @click="consulting">
              <p>立即咨询</p>
            </div>
          </div>
          <el-dialog
            title="联系客服"
            :show-close="false"
            width="400px"
            :visible.sync="dialogTableVisible"
          >
            <div class="inquiry">
              <div class="DialogTop">
                <div class="title">24小时客服电话</div>
                <div class="title1">029-85233571</div>
                <div class="img">
                  <img src="../../assets/image/baojia03.png" alt="" />
                </div>
                <div class="bom">扫一扫添加客服微信</div>
              </div>
              <p class="colseBox" @click="DialogClose">
                <i class="el-icon-close"></i>
              </p>
            </div>
          </el-dialog>
        </div>
        <el-tabs
          class="ServiceTopBoxTabs"
          v-model="activeName"
          @tab-click="handleClick"
        >
          <el-tab-pane label="上架服务" name="1">
            <div class="seckill-goods" v-if="serviceList.length != 0">
              <ul class="clearfix">
                <li
                  v-for="item in serviceList"
                  :key="item.id"
                  @click="goServerDetail(item.id)"
                >
                  <div class="ListTop">
                    <img :src="item.coverImg" alt="" />
                  </div>
                  <p class="pTitle">{{ item.serviceName }}</p>
                  <div class="ListBottom">
                    {{ item.servicePrice + getPrizeType(item.priceType) }}
                  </div>
                </li>
              </ul>
            </div>
            <div class="emptyList" v-else>
              <p>暂无数据</p>
            </div>
          </el-tab-pane>
          <el-tab-pane label="设计案例" name="2">
            <div class="seckill-goods" v-if="engCase.length != 0">
              <ul class="clearfix">
                <li
                  style="cursor: pointer"
                  v-for="item in engCase"
                  :key="item.id"
                  @click="goCaseDetail(item)"
                >
                  <div class="ListTop">
                    <img :src="item.coverImg" alt="" />
                  </div>
                  <div class="ListBottom">
                    <div class="ListLeft">
                      <div style="display: flex;align-items: center;justify-content: space-between">
                        <p class="pTitle" style="width: 200px;">{{ item.caseName }}</p>
                        <p class="ListRight" style="width: 100px;">{{ item.budget }}</p>
                      </div>

                      <p class="pTitle2">
                        {{
                          getHouseType(item.houseType) +
                          "丨" +
                          getDardensStyle(item.gardensStyle) +
                          "丨" +
                          item.area +
                          "㎡"
                        }}
                      </p>
                    </div>

                  </div>
                </li>
              </ul>
            </div>
            <div class="emptyList" v-else>
              <p>暂无数据</p>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="advertisement">
        <div class="guanggao_f">
          <wantQuote />
        </div>
        <div class="guanggao_t">
          <advertisementLeft :wideUrl="wideUrl" @click.native="goRoute" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components//Breadcrumb/Breadcrumb.vue";
import advertisementLeft from "@/components/advertisement/advertisementLeft.vue";
import wantQuote from "@/components/wantQuote/wantQuote.vue";
import Stars from "@/components/start/index.vue";
import { getDicts } from "@/request/dict/data";
import { getCaseList } from "@/request/case";
import { getConstructService } from "@/request/findServer";
import { selectDictLabel, selectDictLabels, handleMoney } from "@/utils/weihua";
import { getFindDesign, getFindDesignDetail } from "@/request/findDesign";
import { getBanner, collection, unCollection } from "@/request/home/home";
export default {
  name: "costEngineerDetails",
  components: { Breadcrumb, advertisementLeft, wantQuote, Stars },
  data() {
    return {
      dialogTableVisible: false,
      inquiry: 0,
      activeName: "1",
      value: 5,
      year: null,
      costEngineList: [],
      engCase: [],
      serviceList: [],
      prizeTypeList: [],
      wideUrl: null,
      url: null,
    };
  },
  activated() {
    this.getDetail();
    this.getCaseList();
    this.getServersList();
    this.getBBannerList();
  },
  created() {
    getDicts("msk_sys_well_style").then((res) => {
      this.styleList = res.data;
      this.styleList.unshift({
        dictLabel: "全部",
        dictValue: "",
      });
    });
    getDicts("msk_sys_house_type").then((res) => {
      this.houseTypeList = res.data;
      this.houseTypeList.unshift({
        dictLabel: "全部",
        dictValue: "",
      });
    });
    getDicts("msk_price_type").then((res) => {
      this.prizeTypeList = res.data;
    });
    getDicts("msk_sys_gardens_style").then((res) => {
      this.dardensStyleList = res.data;
      this.dardensStyleList.unshift({
        dictLabel: "全部",
        dictValue: "",
      });
    });
  },
  methods: {
    getBBannerList() {
      getBanner({ advertiseType: 7 }).then((res) => {
        if (res.data && res.data[0]) {
          this.wideUrl = res.data[0].filePath;
          this.url = res.data[0].routeContent;
        }
      });
    },
    goRoute() {
      if (this.url) {
        window.open(this.url,"_blank");
      } else {
        return 
      }
    },
    getDetail() {
      const id = this.$route.query.id;
      getFindDesignDetail(id).then((res) => {
        this.costEngineList = res.data;
        this.costEngineList.year = this.getYearNum(
          res.data.jobStartTime,
          res.data.jobEndTime
        );
      });
    },
    //设计案例
    getCaseList() {
      const bizId = this.$route.query.id;
      getCaseList({ bizId }).then((res) => {
        const arr = res.rows.slice(0, 6);
        arr.forEach((item) => {
          item.budget = handleMoney(item.budget);
        });
        this.engCase = arr;
      });
    },
    //上架服务
    getServersList() {
      const bizId = this.$route.query.id;
      getConstructService({ bizId }).then((res) => {
        this.serviceList = res.rows.slice(0, 6);
      });
    },
    goServerDetail(e) {
      this.$router.push({ name: "FindServiceDetails", query: { id: e } });
    },
    getHouseType(value) {
      return selectDictLabel(this.houseTypeList, value);
    },
    getDardensStyle(value) {
      return selectDictLabel(this.dardensStyleList, value);
    },
    getPrizeType(value) {
      return selectDictLabel(this.prizeTypeList, value);
    },
    follow(e) {
      const data = {
        bizId: this.$route.query.id,
        bizType: 3,
      };
      if (e.isCollect == 0) {
        collection(data).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "收藏成功，请登录小程序查看",
              type: "success",
            });
            this.getDetail()
          }
        });
      } else {
        unCollection(data).then((res) => {
          if(res.code == 200){
            this.$message({
              message: "取消成功",
              type: "success",
            });
            this.getDetail()
          }
          
        });
      }

      // this.$message({
      //   message: "功能开发中,敬请期待",
      //   type: "success",
      // });
    },
    getYearNum(startTime, endTime) {
      if (startTime && endTime) {
        var timestr = startTime.replace(/-/g, "-");
        var startTime1 = new Date(timestr);
        var timestr1 = endTime.replace(/-/g, "-");
        var endTime1 = new Date(timestr1);
        var y = endTime1.getFullYear();
        var y1 = startTime1.getFullYear();
        var m = endTime1.getMonth() + 1;
        var m1 = startTime1.getMonth() + 1;
        if (y == y1) {
          this.year = "年";
          return 1;
        } else {
          this.year = "年";
          return y - y1;
        }
      } else {
        return;
      }
    },
    consulting() {
      const token = sessionStorage.getItem("token");
      if (token) {
        this.dialogTableVisible = true;
      } else {
        this.$message({
          message: "您还没有登录，请登录后查看",
          type: "success",
        });
        this.$router.push({ path: "/HomeView" });
        sessionStorage.setItem("loading", true);
        this.$router.go(0);
      }
    },
    DialogClose() {
      this.dialogTableVisible = false;
    },
    goCaseDetail(e) {
      this.$router.push({
        path: "/CasePresentationDetails",
        query: {
          id: e.id,
        },
      });
    },
    handleClick() {},
  },
};
</script>
<style scoped>
::v-deep .el-breadcrumb__inner.is-link:hover {
  color: #219560 !important;
}
::v-deep .el-tabs__header {
  margin: 0;
}
::v-deep .el-tabs__item:hover {
  color: #219560;
  cursor: pointer;
}
::v-deep .el-tabs__item.is-active {
  font-size: 16px;
  color: #219560;
}

::v-deep .el-tabs__active-bar {
  background-color: #188252;
  height: 0;
  border-radius: 2px;
}

::v-deep.el-breadcrumb__inner:hover {
  color: #219560;
  cursor: pointer;
}
.myrou /deep/ .el-breadcrumb__inner {
  font-weight: 400;
}
</style>

<style  lang="scss">
.el-rate {
  height: 42px;
  .el-rate__item {
    height: 42px;
    .el-rate__icon {
      font-size: 25px;
      line-height: 42px;
      margin-right: 0;
    }
    .el-rate__decimal {
      font-size: 25px;
      line-height: 42px;
      margin-right: 0;
    }
  }
}
.el-dialog__body {
  padding: 0 20px;
}
.inquiry {
  width: 347px;
  height: 401px;
  .DialogTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 30px;
    }
    .title1 {
      font-size: 30px;
      font-family: DIN;
      font-weight: 500;
      color: #f66a1d;
      line-height: 50px;
    }
    .img {
      width: 258px;
      height: 258px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .bom {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
    }
  }
  .colseBox {
    cursor: pointer;
    position: absolute;
    top: 4px;
    right: -30px;
    border: 1px solid #bdbdbd;
    border-radius: 50%;
    i {
      font-size: 24px;
      color: #bdbdbd;
    }
  }
}
.costEngineerDetails {
  width: 1320px;
  margin: 0 auto;
  .route {
    width: 100%;
    height: 30px;
    padding-top: 20px;
  }
  .costEng {
    display: flex;
    justify-content: space-between;
    .costEng_info {
      width: 72%;
      display: flex;
      flex-direction: column;
      margin-right: 40px;
      .costEng_info_con {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        padding: 10px;
        margin-bottom: 40px;
        background-color: #fafafa;
        .costEng_img {
          width: 27%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;

          .img {
            width: 248px;
            height: 318px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .info {
          width: 1320px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-left: 40px;
          padding-top: 28px;
          .info_title {
            display: flex;
            justify-content: flex-start;
            .name {
              display: flex;
              height: 42px;
              line-height: 42px;
              width: 100%;
              margin: 0 24% 20px 0;
              p {
                font-size: 20px;
                margin-right: 10px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #4d4d4d;
              }
            }
            .share {
              width: 240px;
              display: flex;
              margin-right: -30px;
              margin-bottom: 20px;
              .shoucang {
                height: 34px;
                width: 100px;
                margin-right: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: linear-gradient(-70deg, #f6461d, #f66a1d);
                // .iconfont {
                //   color: #f66a1d;
                //   margin-left: 5px;
                // }
                p {
                  cursor: pointer;
                  font-size: 12px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #fff;
                }
              }
              .fenxiang {
                height: 28px;
                width: 28%;
                display: flex;
                flex-direction: column;
                cursor: pointer;
                i {
                  display: inline-block;
                  font-size: 14px;
                  margin-left: 5px;
                  color: #949997;
                  height: 16px;
                  margin-bottom: 2px;
                }
                p {
                  width: 50px;
                  cursor: pointer;
                  font-size: 12px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #adb2b0;
                }
              }
            }
          }
          .info_case {
            display: flex;
            justify-content: flex-start;
            margin-bottom: 20px;
            .case_f {
              display: flex;
              flex-direction: column;
              width: 18%;
              p:nth-child(1) {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
                margin-bottom: 10px;
              }
              p:nth-child(2) {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #4d4d4d;
              }
            }
            .case_t {
              display: flex;
              flex-direction: column;
              width: 100px;
              p:nth-child(1) {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
                margin-bottom: 10px;
              }
              p:nth-child(2) {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #4d4d4d;
              }
            }
            .case_th {
              display: flex;
              flex-direction: column;
              width: 200px;
              p:nth-child(1) {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
                margin-bottom: 10px;
              }
              p:nth-child(2) {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #4d4d4d;
              }
            }
            .info_certificate {
              display: flex;
              justify-content: flex-start;
              margin-bottom: 10px;
              width: 200px;
              .certificate {
                p:nth-child(1) {
                  font-size: 14px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #999999;
                  margin-bottom: 10px;
                }
                p:nth-child(2) {
                  font-size: 14px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #4d4d4d;
                }
              }
            }
          }

          .info_introduce {
            display: flex;
            justify-content: flex-start;
            margin-bottom: 20px;
            .introduce {
              p:nth-child(1) {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
                margin-bottom: 10px;
              }
              p:nth-child(2) {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #636664;
                overflow: hidden;
                line-height: 1.8;
                width: 96%;
                display: -webkit-box; /*弹性伸缩盒子*/
                -webkit-box-orient: vertical; /*子元素垂直排列*/
                -webkit-line-clamp: 2; /*可以显示的行数，超出部分用...表示*/
                text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
              }
            }
          }
          .inquiry {
            width: 154px;
            height: 40px;
            background: #f66a1d;
            border-radius: 3px;
            p {
              cursor: pointer;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #ffffff;
              line-height: 40px;
              text-align: center;
            }
          }
        }
      }
      .ServiceTopBoxTabs {
        width: 106%;
        .el-tabs__header {
          margin: 0;
        }
        .el-tabs__item.is-active {
          font-size: 16px;
          color: #188252;
          font-weight: 600;
        }

        .el-tabs__active-bar {
          background-color: #188252;
          height: 0;
          border-radius: 2px;
        }

        .el-tabs__item {
          font-size: 16px;
          color: #4a4a4a;
        }

        .el-tabs__item:hover {
          font-size: 16px;
          color: #188252;
        }

        .el-tabs__nav-wrap::after {
          height: 0;
          border-color: transparent !important;
        }
        .emptyList {
          width: 100%;
          height: 300px;
          p {
            color: #ccc;
            font-size: 24px;
            text-align: center;
            line-height: 300px;
          }
        }
        .seckill-goods {
          display: inline-block;

          .clearfix {
            margin-left: -35px;
            li {
              width: 309px;
              margin-top: 20px;
              float: left;
              flex: 1;
              margin-left: 36px;
              background-color: #FFFFFF;
              .ListTop {
                cursor: pointer;
                width: 309px;
                height: 186px;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
              .pTitle {
                font-size: 14px;
                cursor: pointer;
                overflow: hidden;
                width: 95%;
                margin: 10px auto 10px;
                color: #000000;
                display: -webkit-box; /*弹性伸缩盒子*/
                -webkit-box-orient: vertical; /*子元素垂直排列*/
                -webkit-line-clamp: 1; /*可以显示的行数，超出部分用...表示*/
                text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
              }
              .ListBottom {
                width: 95%;
                margin: 5px auto;
                font-size: 14px;
                color: #f66a1d;
                display: flex;
                justify-content: space-between;
                .ListLeft {
                  .pTitle {
                    font-size: 14px;
                    cursor: pointer;
                    overflow: hidden;
                    color: #000000;
                    width: 100%;
                    display: -webkit-box; /*弹性伸缩盒子*/
                    -webkit-box-orient: vertical; /*子元素垂直排列*/
                    -webkit-line-clamp: 1; /*可以显示的行数，超出部分用...表示*/
                    text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
                  }
                  .pTitle:hover {
                    font-size: 14px;
                    cursor: pointer;
                    overflow: hidden;
                    color: #188252;
                    width: 100%;
                    display: -webkit-box; /*弹性伸缩盒子*/
                    -webkit-box-orient: vertical; /*子元素垂直排列*/
                    -webkit-line-clamp: 1; /*可以显示的行数，超出部分用...表示*/
                    text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
                  }
                  .pTitle2 {
                    padding-bottom: 10px;
                    font-size: 14px;
                    padding-top: 5px;
                    color: #999999;
                  }
                }

                .ListRight {
                  font-size: 16px;
                  font-weight: bold;
                  color: #ee6a31;
                  text-align: right;
                  //padding-right: 20px;
                }
              }
            }
          }
        }
      }
    }

    .advertisement {
      width: 23%;
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      .guanggao_f {
        margin: 0 auto 20px auto;
      }
      .guanggao_t {
        margin: 0 auto 20px auto;
      }
    }
  }
}
</style>